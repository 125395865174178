import React, { useState, useEffect } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { getProjectById, getDocumentById, getSingleChat } from '../../common/api/routes';
import axios from "axios";

import Footer from './layout/footer/Footer';
import Header from './layout/header/Header';
import Profile from './layout/content/profile/profile';
import Privacy from './layout/content/privacy/privacy';
import Terms from './layout/content/terms/terms';
import Documents from './layout/content/projects/Projects.view';

import './layout/content/projects/projects.scss';

const Dashboard = () => {
  const [ projectData, setProjectData ] = useState({})
  const [ loadedDocument, setLoadedDocument ] = useState({})
  const [ loadedMessage, setLoadedMessage ] = useState({});
  const { url } = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    const projectId = localStorage.getItem('selected-project')
    if(projectId && Number(projectId) > 0){
      axios.get(getProjectById(projectId)).then(response => {
        setProjectData(response.data.data)
      }).catch(error => {
        console.log(error)
      })
    }else{
              localStorage.setItem('stayLoggedIn', false);
              localStorage.removeItem('user')
              window.location.href = "https://klantenportaal.gaelensbouwprojecten.be/";
    }
  
  }, [])

  useEffect(() => {
    if(location.search.length > 0) {
      const searchQuery = location.search.substr(1, location.search.length).split("&")
      const type = searchQuery[0].split("=")[1];
      const id = searchQuery[1].split("=")[1];

      if(type == "0") {
        axios.get(getSingleChat(id)).then(response => {
          console.log(response.data.data)
          setLoadedMessage(response.data.data);
        }).catch(error => console.log(error))
      }
      else if(type == "1") {
        axios.get(getDocumentById(id)).then(response => {
          setLoadedDocument({...response.data.data, viewed: false})
        }).catch(error => console.log(error))
      }
    }
  }, [location.search])

  return (
    <Grid container>
      <Header projectData = {projectData} loadedMessage = {loadedMessage} />
      <Switch>
        <Route path={`${url}/projects`}>
          <Documents projectData = {projectData} loadedDocument = {loadedDocument} setLoadedDocument = {setLoadedDocument} />
        </Route>
        <Route path={`${url}/profile`}>
          <Profile projectData = {projectData} />
        </Route>
        <Route path={`${url}/privacy`}>
          <Privacy />
        </Route>
        <Route path={`${url}/terms`}>
          <Terms />
        </Route>
      </Switch>
      <Hidden xsDown>
        <Footer />
      </Hidden>
    </Grid>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
