import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { SpaceRow } from '../../../../../../components/space/Space';
import { SQLDateToString } from "../../../../../../common/utils/format";

import './purchase_info.scss';

export default function PurchaseInfo(props) {
  const { data, userData, secondary, toggleVisibility } = props;

  useEffect(() => {
    console.log(data)
  }, [data])

  function getUserAccess() {
    if(data?.user_id === userData?.id) {
      return data.access;
    }
    else {
      if(data?.childs) {
        const childUser = data?.childs.find(child => child.user_id === userData.id)
        return childUser?.access || 0;
      }
      else {
        return 0;
      }
    }
  }

  function getUserStatus() {
    if(data?.user_id === userData?.id) {
      return data.status;
    }
    else {
      if(data?.childs) {
        const childUser = data?.childs.find(child => child.user_id === userData.id)
        return childUser?.status || 0;
      }
      else {
        return 0;
      }
    }
  }

  function getUserPercentage() {
    if(data?.user_id === userData?.id) {
      return data.percentage;
    }
    else {
      if(data?.childs) {
        const childUser = data.childs?.find(child => child.user_id === userData.id)
        return childUser?.percentage || 0;
      }
      else {
        return 0;
      }
    }
  }

  return (
    <Grid container>
      {secondary && (
        <>
          <Grid container>
            <div className="purchase-info-divider" />
          </Grid>
          <SpaceRow top="30" />
        </>
      )}
      <Grid container>
        <Grid container item sm={6} md={3}>
          <p className="main-font-regular font-size-14 font-color-grey">Datum</p>
        </Grid>
        <Grid container item sm={6} md={9}>
          <p className="main-font-semi-bold font-size-14 font-color-black">{SQLDateToString(data.created_at)}</p>
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={3}>
          <p className="main-font-regular font-size-14 font-color-grey">Project</p>
        </Grid>
        <Grid container item sm={6} md={9}>
          <p className="main-font-semi-bold font-size-14 font-color-black">{data?.project?.name || ""}</p>
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={3}>
          <p className="main-font-regular font-size-14 font-color-grey">Entiteit</p>
        </Grid>
        <Grid container item direction="column" sm={6} md={9}>
          {data.user_units && data.user_units.length > 0 && data.user_units.map((unit) => (
            <p className="main-font-semi-bold font-size-14 font-color-black">{unit.units.type.title} {unit.units.general_number}</p>
          ))}
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={3}>
          <p className="main-font-regular font-size-14 font-color-grey">Status</p>
        </Grid>
        <Grid container item sm={6} md={9}>
          <p className="main-font-semi-bold font-size-14 font-color-black">{getUserStatus() == 0 ? 'Volle eigendom' : getUserStatus() == 1 ? 'Naakte eigendom' : 'Vruchtgebruiker'} {getUserPercentage() || 0}%</p>
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={3}>
          <p className="main-font-regular font-size-14 font-color-grey">Bevoegdheid</p>
        </Grid>
        <Grid container item sm={6} md={9}>
          <p className="main-font-semi-bold font-size-14 font-color-black">{getUserAccess() == 0 ? 'Beslissingsnemer' : 'Lezer'}</p>
        </Grid>
      </Grid>
      <SpaceRow top="55" />
      <Grid container>
        <Grid container item sm={12}>
          <button type="button" className="purchase-info-owner-button center-content relative animatable-400 ease-in-out-quart" onClick = {() => toggleVisibility(true) } >
            <img src={require('../../../../../../assets/images/icon/users_darkbleu.svg')} alt="users" className="purchase-info-owner-icon animatable-400 ease-in-out-quart" />
            <img src={require('../../../../../../assets/images/icon/users_white.svg')} alt="users" className="purchase-info-owner-icon animatable-400 ease-in-out-quart" />
            <p className="main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart">Mede-eigennaar(s)</p>
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}

PurchaseInfo.propTypes = {
  data: PropTypes.object,
  secondary: PropTypes.bool,
};

PurchaseInfo.defaultProps = {
  data: {
    date: '23/04/2020',
    project: 'Laurins Garden',
    units: [
      {
        title: 'Appartement 1.4',
        type: 1,
      },
      {
        title: 'Parking  1.2',
        type: 2,
      },
      {
        title: 'Berging 3',
        type: 3,
      },
    ],
    status: 'Vruchtgebruiker 50%',
    jurisdiction: 'Beslissingsnemer',
  },
  secondary: false,
};
