import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import axios from "axios";
import PropTypes from 'prop-types';
import SideCardHeader from '../SideCardHeader';
import { delay } from "lodash";
import { SpaceRow } from '../../../../../../components/space/Space';
import { SQLDateTimeToString } from '../../../../../../common/utils/format';
import { getAllNotificationByUser } from "../../../../../../common/api/routes";

import './notification.scss';
import SlidingCard from '../../../../../../components/shared/sliding_card/sliding_card';

function NotificationItem(props) {
  const { line, data, handleMessageNotifClick, handleDocumentNotifClick } = props;

  const getDocumentCategory = (id) => {
    if(id == 1) {
      return "Gebouw";
    }
    else if(id == 2) {
      return "Aankoop";
    }
    else if(id == 3) {
      return "Plannen";
    }
    else if(id == 4) {
      return "Afwerking";
    }
    else if(id == 5) {
      return "Facturatie";
    }
    else if(id == 6) {
      return "Beheer";
    }
    else {
      return "";
    }
  }

  return (
    <div className="notification-list-item cursor-pointer" onClick = {data?.type === 0 ? () => {handleDocumentNotifClick(data.document_id);} : () => {handleMessageNotifClick(data.chat_id);}}>
      <div className="notification-list-item-line-container">
        <div className="notification-list-item-dot" />
        <div className="notification-list-item-line" style={{ display: line ? 'block' : 'none' }} />
      </div>
      <div className="flex-column width-auto margin-left-30">
        <p className="main-font-semi-bold font-size-14 font-color-blue-grey">{data?.type === 0 ? data?.document?.filename : 'Nieuw bericht'}</p>
        <SpaceRow top="5" />
        <p className="main-font-regular font-size-14 font-color-blue-grey">{data?.document_id ? data?.type === 1 ? `U hebt een nieuwe bericht voor het document ${data?.document?.filename} ` : `U heeft een nieuw document met betrekking tot ${getDocumentCategory(data?.document?.category_id)} ontvangen` : `U heeft een nieuw bericht`}</p>
        <SpaceRow top="5" />
        <p className="main-font-regular font-size-14 font-color-grey">{`${SQLDateTimeToString(data?.created_at)}`}</p>
      </div>
    </div>
  );
}

export default function Notification(props) {
  const { isOpen, toggleVisibility, projectData, notificationList, setNotificationList, handleMessageNotifClick, handleDocumentNotifClick } = props;

  return (
    <SlidingCard isOpen={isOpen} ShowSligingCard={() => toggleVisibility()}>
      <img
        src={require('../../../../../../assets/images/icon/cross_gray.svg')}
        alt="close"
        onClick={() => toggleVisibility()}
        className="cursor-pointer close-btn"
      />
      <img
        src={require('../../../../../../assets/images/iconround/back_gray.svg')}
        alt="back"
        onClick={() => toggleVisibility()}
        className="cursor-pointer back-btn"
      />
      <Grid container style={{ padding: 20 }} className="height-auto">
        <SideCardHeader
          title="Notificaties"
          toggleVisibility={toggleVisibility}
          headerImage={require('../../../../../../assets/images/icon/notif_darkbleu.svg')}
        />
        <div className="notification-list-container">
          {notificationList && notificationList.length > 0 && notificationList.map((notif, index) => (
            <NotificationItem line={index !== (notificationList.length - 1)} data={notif} handleMessageNotifClick = {handleMessageNotifClick} handleDocumentNotifClick = {handleDocumentNotifClick} />
          ))}
        </div>
      </Grid>
    </SlidingCard>
  );
}

Notification.propTypes = {
  toggleVisibility: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

Notification.defaultProps = {
  isOpen: false,
};

NotificationItem.propTypes = {
  line: PropTypes.bool,
  data: PropTypes.object,
};

NotificationItem.defaultProps = {
  line: true,
  data: {
    name: '*Document Name*',
    type: 'document',
    category: 'Facturatie',
    date: '15/02/19',
    time: '13:45',
  },
};
