import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import axios from "axios";
import { useLocation, useHistory } from 'react-router-dom';

import { SpaceRow } from '../../../components/space/Space';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { userUpdate, newPassword, createNotification } from '../../../common/api/routes';
import Loader from "../../../components/loader/loader";
import ErrorMessage from "../../../components/error-message/ErrorMessage";
import ErrorModal from "../../../components/error_modal/error_modal"

import "../login.scss";

export default function NewPassword(props) {
    const [passwordState, setPasswordState] = useState('');
    const [ loadState, setLoadState ] = useState(false);
    const [ lastErrorState, setLastErrorState ] = useState(null);
    const [ animationState, setAnimationState ] = useState(true);
    const [ passwordStateError, setPasswordStateError ] = useState(false);
    const [ userData, setUserData ] = useState(false)
    const [ stayLoggedInState, setStayLoggedInState ] = useState(false)
    const [ sendAccessState, setSendAccessState ] = useState(false)

    const { changePhase } = props;
    const emailCheckRef = useRef(0)
    const location = useLocation();
    const history = useHistory();
    const errorModalRef = useRef(0)

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        setAnimationState(false)
        
        const query = new URLSearchParams(location.search)

        if(query.get("user") === "true") {
            setSendAccessState(true)
        }
        else {
            setUserData( JSON.parse(localStorage.getItem("user")) )
            isLoggedIn()
        }
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [])

    function isLoggedIn() {
        let stayLoggedIn = localStorage.getItem('stayLoggedIn');
        if(stayLoggedIn) {
            stayLoggedIn = JSON.parse(stayLoggedIn);
            setStayLoggedInState(stayLoggedIn);
        }
    }
    
    const handlePasswordChange = (event) => {
        setPasswordStateError(false);
        setPasswordState(event.target.value);
    };

    const isValid = () => {
        let validity = true;
        if (passwordState === '' || passwordStateError) {
            validity = false;
            setPasswordStateError(true);
        }
        return validity;
    }; 

    const handlePasswordUpdateEvent = () => {
        const query = new URLSearchParams(location.search)
        let token = query.get("token")
        const credentials = Buffer.from(token, 'base64').toString().split(',');

        if(emailCheckRef.current.getValue()) {

            if(!sendAccessState) {
                if(isValid() && userData) {
                    setLoadState(true)
                    axios.put(userUpdate(userData.id), {
                        password: passwordState,
                        first_login: false,
                    }).then(response => {
                        setLoadState(false)
                        if(response.data.success) {
                            setAnimationState(true);
                            setTimeout(() => {
                                // we set a delay of 400 before changing routes so that the animation has time to complete 
                                changePhase(3);
                            }, 500)
                        }
                    }).catch(error => {
                        errorModalRef.current.openModal(error.response.data.data)
                        setLoadState(false)
                    })
                }
            }
            else {
                // let token = location.search.split("?")[1];
                // token = token.split("=")[1];
                // token = token.split("&")[0];
                // console.log("userData",userData)

                if(isValid()) {
                    setLoadState(true)
                    axios.post(newPassword, {
                        token,
                        password: passwordState,
                        first_signIn: true
                    }).then(response => {
                        if(response.data.data.message === "your login token expired") {
                            errorModalRef.current.openModal('your token is invalid or expired')
                        }
                        else {
                            setLoadState(false)
                            localStorage.setItem('user', JSON.stringify(response.data.data))
                            if(response.data.data.first_login) {
                                axios.put(userUpdate(response.data.data.id), {
                                    first_login: false,
                                }).then(response2 => {
                                    setLoadState(false)
                                    if(response2.data.success) {
                                        setAnimationState(true);
                                        setTimeout(() => {
                                            // we set a delay of 400 before changing routes so that the animation has time to complete 
                                            changePhase(3);
                                        }, 500)
                                    }
                                }).catch(error => {
                                    errorModalRef.current.openModal(error.response.data.data)
                                    setLoadState(false)
                                })

                                axios.post(createNotification, {
                                    notification_type: 0,
                                    user_id: response.data.data.id,
                                    type: 7,
                                    title: 'First Login',
                                    description: `${response.data.data.firstName} ${response.data.data.lastName} heeft een paswoord gecreëerd en heeft zich voor de eerste keer geregistreerd op het platform.`
                                }).catch(error => console.log(error))
                            }
                            else {
                                setTimeout(() => {
                                    // we set a delay of 400 before changing routes so that the animation has time to complete 
                                    changePhase(3);
                                }, 500)
                            }
                        }
                    }).catch(error => console.log(error))
                }
            }
        }
        else {
            emailCheckRef.current.showError();
        }
    }

    const handleKeyPress = (event) => {
        if(event.keyCode === 13) {
            handlePasswordUpdateEvent();
        }
    }

    const stayLoggedIn = () => {
        localStorage.setItem('stayLoggedIn', true);
    }

    const removeStayLoggedIn = () => {
        localStorage.setItem('stayLoggedIn', false);
    }

    return(
        <Grid container className = "animatable-400 ease-in-out-quart" style = {{opacity: animationState ? 0 : 1, transform: animationState ? 'translateY(-25px)' : 'translateY(0px)'}} >
            <SpaceRow top="21" />
            <Grid container direction = "column">
                <p className = "main-font-regular font-size-14 font-color-white">Welkom {userData?.firstName} {userData?.lastName} op het Gaelens-platform. Hier vindt u alle documenten over uw aankoop. Kies uw wachtwoord om toegang te krijgen. </p>
            </Grid>
            <SpaceRow top="37" />

            <Grid container>
            <div className="login-input-container">
                <div
                    className="login-input-container-layer animatable-400 ease-in-out-quart"
                    style={{ transform: passwordStateError ? 'scaleX(1)' : 'scaleX(0)' }}
                />
                <div className="login-input-container-main">
                <img
                    className="login-input-icon flex-shrink-disable"
                    src={require('../../../assets/images/icon/lock_white.svg')}
                    alt="person white"
                />
                <input
                    type="password"
                    className="login-input flex-shrink-disable main-font-regular font-size-14 font-color-white"
                    value={passwordState}
                    placeholder="Wachtwoord"
                    onChange={handlePasswordChange}
                />
                </div>
            </div>
            </Grid>
            <SpaceRow top="45" />

            <Grid container justify="space-between" alignItems="center">
            <div className="flex-row vertical-center">
                <Checkbox initialState = {stayLoggedInState} isActive = {stayLoggedIn} isUnactive = {removeStayLoggedIn}  />
                <p
                    className="main-font-regular font-size-14 font-color-white margin-left-5"
                >
                    Aangemeld blijven
                </p>
            </div>
            </Grid>
            <SpaceRow top="45" />

            <Grid container wrap="nowrap" alignItems="flex-start">
            <Checkbox ref = {emailCheckRef} />
            <p
                className="main-font-regular font-size-14 font-color-white margin-left-5"
            >
                Ik ga akkoord om per e-mail op de hoogte te worden gehouden omtrent wijzigingen en nieuwe documenten.
            </p>
            </Grid>
            <SpaceRow top="45" />

            <Grid container>
                <Grid container justify = "center" className = "absolute">
                    <Loader animationState = {loadState} />
                </Grid>
                <button
                    type="button"
                    className="login-button center-content animatable-400 ease-in-out-quart"
                    onClick = {handlePasswordUpdateEvent}
                    style = {{opacity: loadState ? 0 : 1, transform: loadState ? 'translateY(-15px)' : 'translate(0px)'}}
                >
                    <p
                    className="main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart"
                    >
                        Aanmelden
                    </p>
                </button>
            </Grid>
            <ErrorModal ref = {errorModalRef} onRoute = {( ) => {history.push("/"); history.go(0);}} />
        </Grid>
    )
}