import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import SideCardHeader from '../SideCardHeader';
import { SpaceRow } from '../../../../../../components/space/Space';
import axios from "axios";

import './project.scss';
import SlidingCard from '../../../../../../components/shared/sliding_card/sliding_card';
import { getUserProject } from "../../../../../../common/api/routes";
import { SQLDateToString } from "../../../../../../common/utils/format";

function ProjectDetail(props) {
  const { data, isActive } = props;
  const history = useHistory();
  const location = useLocation();

  const handleProjectChange = () => {
    localStorage.setItem('selected-project', data.id)
    if(location.pathname === "/dashboard/projects") {
      history.go(0);
    }
    else {
      history.push("/dashboard/projects");
      history.go(0);
    }
  }

  return (
    <div className="flex-row width-auto">
      <Grid container>
        <Grid container item direction="column" xs={6}>
          <p className="main-font-regular font-size-14 font-color-grey">Project</p>
          <SpaceRow top="25" />
          <p className="main-font-regular font-size-14 font-color-grey">Datum</p>
          <SpaceRow top="25" />
          <p className="main-font-regular font-size-14 font-color-grey">Entiteit</p>
        </Grid>
        <Grid container item direction="column" xs={6}>
          <p className="main-font-semi-bold font-size-14 font-color-black">{data?.project?.name || ""}</p>
          <SpaceRow top="25" />
          <p className="main-font-semi-bold font-size-14 font-color-black">{data?.created_at ? SQLDateToString(data.created_at) : ""}</p>
          <SpaceRow top="25" />
          <div className="flex-column width-auto">
            {data.user_units && data.user_units.length > 0 && data.user_units.map((unit) => (
              <>
                <p className="main-font-semi-bold font-size-14 font-color-black">{unit?.units?.type?.title || ""} {unit?.units?.general_number}</p>
                <SpaceRow top="3" />
              </>
            ))}
            <p className="main-font-semi-bold font-size-14 font-color-black">{data?.project_units?.general_number || ""}</p>
            <SpaceRow top="3" />
          </div>
        </Grid>
        <Grid container>
          <SpaceRow top="23" />
        </Grid>
        <Grid container>
          <button type="button" className={`${isActive ? 'project-button-active' : 'project-button-inactive'} center-content`} onClick={handleProjectChange}>
            <p className={`main-font-semi-bold font-size-14 ${isActive ? 'font-color-blue-grey' : 'font-color-white'}`}>{isActive ? 'Actief' : 'Selecteren'}</p>
          </button>
        </Grid>
      </Grid>
    </div>
  );
}

export default function Project(props) {
  const [ projectData, setProjectData ] = useState([])
  const { isOpen, toggleVisibility } = props;
  const selectedProject = localStorage.getItem('selected-project');

  useEffect(() => {
    const userData = JSON.parse( localStorage.getItem('user') );
    axios.get(getUserProject(userData.id)).then(response => {
      setProjectData(response.data.data);
    }).catch(error => {
      console.log(error)
    })
  }, [])

  return (
    <SlidingCard isOpen={isOpen} ShowSligingCard={() => toggleVisibility()}>
        <img
        src={require('../../../../../../assets/images/icon/cross_gray.svg')}
        alt="close"
        onClick={() => toggleVisibility()}
        className="cursor-pointer close-btn"
      />
      <img
        src={require('../../../../../../assets/images/iconround/back_gray.svg')}
        alt="back"
        onClick={() => toggleVisibility()}
        className="cursor-pointer back-btn"
      />
      <Grid container style={{ padding: 20 }} className="height-auto">
        <SideCardHeader
          title="Projecten"
          toggleVisibility={toggleVisibility}
          headerImage={require('../../../../../../assets/images/icon/building_darkbleu.svg')}
        />
        <SpaceRow top="48" />
        <div className="project-container">
          {projectData && projectData.length > 0 && projectData.map((project, index) => (
            <>
              <ProjectDetail isActive = {selectedProject == project.id} data = {project} />
              {index !== (projectData.length - 1) && (
                <>
                  <SpaceRow top="30" />
                  <div className="project-divider" />
                  <SpaceRow top="30" />
                </>
              )}
            </>
          ))}
        </div>
      </Grid>
    </SlidingCard>
  );
}

ProjectDetail.propTypes = {
  data: PropTypes.object,
  isActive: PropTypes.bool,
};

ProjectDetail.defaultProps = {
  data: {
    project: 'Laurins Garden',
    date: '12/03/19',
    units: [
      {
        type: 1,
        title: 'Appartment 1.2',
      },
      {
        type: 2,
        title: 'Parking 1.3',
      },
      {
        type: 3,
        title: 'Berging 2',
      },
    ],
  },
  isActive: false,
};
Project.propTypes = {
  isOpen: PropTypes.bool,
  toggleVisibility: PropTypes.func,
};

Project.defaultProps = {
  isOpen: false,
  toggleVisibility: () => {},
};
