import request from '../../../../../common/request';

export const getChoicesList = (setFetching, setResponse, setError) => {
  setFetching(true);

  const response = request.get('/category');

  response
    .then((res) => {
      setFetching(false);
      let cateogryLimit = res.data.data.filter(category => category.id <= 6);
      setResponse(cateogryLimit);
    })
    .catch((err) => {
      setFetching(false);
      console.log(err)
    });
};

export const getProjectDocuments = (projectId, setFetching, setResponse, setError) => {
  setFetching(true);

  const response = request.get(`/document/project/${projectId}`);

  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
    })
    .catch((err) => {
      setFetching(false);
      setError(err.response);
    });
};
