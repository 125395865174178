/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import SideCardHeader from '../SideCardHeader';
import { SpaceRow } from '../../../../../../components/space/Space';

import './owners.scss';
import SlidingCard from '../../../../../../components/shared/sliding_card/sliding_card';
import { SQLDateToString } from '../../../../../../common/utils/format';
import { getProjectById } from "../../../../../../common/api/routes";
import axios from 'axios';

function Owner(props) {
  const { data, projectData } = props;

  return (
    <div className="owner-container">
      <img src={require('../../../../../../assets/images/icon/person_darkbleu.svg')} alt="building" className="owners-project-icon flex-shrink-disable" />
      <div className="owners-project-title-container flex-shrink-disable">
        <p className="main-font-regular font-size-14 font-color-grey">Naam</p>
        <SpaceRow top="25" />
        <p className="main-font-regular font-size-14 font-color-grey">Status</p>
      </div>
      <div className="flex-column width-auto">
        <p className="main-font-semi-bold font-size-14 font-color-black">{data?.firstName || ""} {data?.lastName || ""}</p>
        <SpaceRow top="25" />
        <p className="main-font-semi-bold font-size-14 font-color-black">{projectData?.status == 0 ? 'Volle eigendom' : projectData?.status == 1 ? 'Naakte eigendom' : 'Vruchtgebruiker'} {projectData?.percentage || 0}%</p>
      </div>
    </div>
  );
}

export default function Owners(props) {
  const [ buyerData, setBuyerData ] = useState({})
  const { isOpen, toggleVisibility, data, userData } = props;

  useEffect(() => {
    if(data && data.id) {
      axios.get(getProjectById(data.id)).then(response => {
        setBuyerData(response.data.data)
      }).catch(error => console.log(error))
    }
  },  [data])

  return (
    <SlidingCard isOpen={isOpen} ShowSligingCard={() => { toggleVisibility(); }}>
      <Grid container style={{ padding: 20 }} className="height-auto">
        <img
          src={require('../../../../../../assets/images/icon/cross_gray.svg')}
          alt="close"
          onClick={() => {
            return toggleVisibility();
          }}
          className="cursor-pointer close-btn"
        />
        <img
          src={require('../../../../../../assets/images/iconround/back_gray.svg')}
          alt="back"
          onClick={() => {
            return toggleVisibility();
          }}
          className="cursor-pointer back-btn"
        />
        <SideCardHeader
          title="Mede-eigenaars"
          toggleVisibility={toggleVisibility}
          headerImage={require('../../../../../../assets/images/icon/users_darkbleu.svg')}
        />
        <SpaceRow top="16" />
        <div className="owners-container">
          <div className="owners-project-container">
            <img src={require('../../../../../../assets/images/icon/building_darkbleu.svg')} alt="building" className="owners-project-icon flex-shrink-disable" />
            <div className="owners-project-title-container flex-shrink-disable">
              <p className="main-font-regular font-size-14 font-color-grey">Project</p>
              <SpaceRow top="25" />
              <p className="main-font-regular font-size-14 font-color-grey">Datum</p>
              <SpaceRow top="25" />
              <p className="main-font-regular font-size-14 font-color-grey">Entiteit</p>
            </div>
            <div className="flex-column width-auto">
              <p className="main-font-semi-bold font-size-14 font-color-black">{buyerData?.project?.name || ""}</p>
              <SpaceRow top="25" />
              <p className="main-font-semi-bold font-size-14 font-color-black">{SQLDateToString(buyerData?.created_at)}</p>
              <SpaceRow top="25" />
              <div className="flex-column">
                {buyerData?.user_units && buyerData.user_units.length > 0 && buyerData.user_units.map(unit => (
                  <>
                    <p className="main-font-semi-bold font-size-14 font-color-black">{unit.units.type.title} {unit.units.general_number}</p>
                    <SpaceRow top="3" />
                  </>
                ))}
              </div>
            </div>
          </div>
          <SpaceRow top="16" />
          <Owner data={buyerData?.user || {}} projectData = {buyerData} />
          <SpaceRow top="16" />
          {buyerData?.childs && buyerData?.childs.map(user => (
            <>
              <Owner data={user.user || {}} projectData = {user} />
              <SpaceRow top="16" />
            </>
          ))}
        </div>
      </Grid>
    </SlidingCard>
  );
}

Owner.propTypes = {
  data: PropTypes.object,
};

Owner.defaultProps = {
  data: {
    name: 'Mr. Mika Vanlaar',
    status: 'full onership indivisibility',
  },
};

Owners.propTypes = {
  isOpen: PropTypes.bool,
  toggleVisibility: PropTypes.func,
};

Owners.defaultProps = {
  isOpen: false,
  toggleVisibility: () => {},
};
