import React, {
  useState, useEffect, useRef, createRef,
} from 'react';
import { Grid, Hidden } from '@material-ui/core';
import axios from 'axios';

import { SpaceRow } from '../../../../../components/space/Space';
import { Checkbox, CheckboxRound } from '../../../../../components/checkbox/Checkbox';
import {
  userUpdate, userGet, getUserTitles, getUserProject,
} from '../../../../../common/api/routes';
import PurchaseInfo from './purchase_info/purchase_info';
import RenewPassword from '../sidecard/renew_password/renew_password';
import Owners from '../sidecard/owners/owners';
import Footer from '../../footer/Footer';
import ErrorModal from "../../../../../components/error_modal/error_modal";

import './profile.scss';

export default function Profile() {
  const [passwordState, setPasswordState] = useState(false);
  const [renewPasswordModalState, setRenewPasswordModalState] = useState(false);
  const [ownerModalState, setOwnerModalState] = useState(false);
  const [userData, setUserData] = useState({});
  const [checkStateOne, setCheckStateOne] = useState(false);
  const [checkStateTwo, setCheckStateTwo] = useState(false);
  const [activeBuyerModal, setActiveBuyerModal] = useState(0);
  const [buyerList, setBuyerList] = useState([]);
  const [userTitles, setUserTitles] = useState([]);
  const [titleDropdownState, setTitleDropdownState] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(1);
  const [update, setUpdate] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  const errorModalRef = useRef(0)

  useEffect(() => {
    getUserData();
    getAllUserTitles();
  }, [update]);

  useEffect(() => {
    axios.get(getUserProject(user.id)).then((response) => {
      setBuyerList(response.data.data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  function getAllUserTitles() {
    axios.get(getUserTitles).then((response) => {
      setUserTitles(response.data.data);
    }).catch((error) => console.log(error));
  }

  function getUserData() {
    if (user.id) {
      axios.get(userGet(user.id)).then((response) => {
        console.log(response.data.data);
        setUserData(response.data.data);
        setCheckStateOne(response.data.data.preference_invoices);
        setCheckStateTwo(response.data.data.preference_communication);
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const handleFieldEdit = (fieldkey, value) => {
    const newUserData = { ...userData };
    newUserData[fieldkey] = value;
    setUserData(newUserData);
  };

  const handleFormUpdate = () => {
    if (user.id) {
      axios.put(userUpdate(user.id), {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        street: userData.street,
        zip: userData.zip,
        city: userData.city,
        comments: userData.comments,
      }).then((response) => {
        console.log(response.data);
      }).catch((error) => {
        if(error.response.data.data.message.includes('unique_email')) {
          errorModalRef.current.openModal('Dit e-mailadres word reeds gebruikt in het systeem.', 'ERROR', 3000)
        }
        console.log(error);
      });
    }
  };

  const handleCheckboxUpdate = (value, type) => {
    if (user.id) {
      if (type === 1) {
        axios.put(userUpdate(user.id), {
          preference_invoices: value,
        }).then((response) => {
          let userDataCopy = { ...userData };
          userDataCopy = { ...userDataCopy, ...response.data.data };
          setUserData(userDataCopy);
        }).catch((error) => {
          console.log(error);
        });
      } else if (type === 2) {
        axios.put(userUpdate(user.id), {
          preference_communication: value,
        }).then((response) => {
          console.log(response.data);
        }).catch((error) => {
          console.log(error);
        });
      } else {

      }
    }
  };

  const handleEmailCheckbox = (value) => {
    axios.put(userUpdate(user.id), {
      preference_communication_email: value,
    }).then((response) => {

    }).catch((error) => console.log(error));
  };

  const setUserTitle = (title_id) => {
    if (user.id) {
      axios.put(userUpdate(user.id), {
        title_id,
      }).then((response) => {
        setTitleDropdownState(false);
        setUpdate(!update);
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  const getUserTitle = (titleId) => {
    const selectedTitle = userTitles.find((titles) => titles.id == titleId);
    return selectedTitle?.title || userTitles[0]?.title;
  };

  const changeUserTitle = (titleId) => {
    setUserTitle(titleId);
  };

  return (
    <div className="profile-container">
      <RenewPassword isOpen={renewPasswordModalState} toggleVisibility={setRenewPasswordModalState} />
      <Owners isOpen={ownerModalState} toggleVisibility={setOwnerModalState} data={activeBuyerModal !== 0 ? buyerList[activeBuyerModal - 1] : []} userData={userData} />
      <Grid container justify="center">
        <Grid container md={11}>
          <Grid container>
            <SpaceRow top="36" />
            <Grid container item sm={6}>
              <Grid container>
                <p className="main-font-bold font-size-20 font-color-black">Mijn gegevens</p>
              </Grid>
              <SpaceRow top="15" />
              <Grid container>
                <div className="profile-details-container">
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Aanspreking</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <div className="profile-title-dropdown-container" tabIndex="1" onBlur={() => setTitleDropdownState(false)}>
                        <div className="profile-title-selected cursor-pointer" onClick={() => setTitleDropdownState(!titleDropdownState)}>
                          <p className="main-font-semi-bold font-size-14 font-color-black">{userTitles && userTitles.length > 0 && getUserTitle(userData.title_id)}</p>
                          <img src={require('../../../../../assets/images/icon/arrow_down.svg')} alt="arrow down" className="profile-title-selected-arrow" />
                        </div>
                        <div className="profile-title-dropdown animatable-400 ease-in-out-quart" style={{ opacity: titleDropdownState ? 1 : 0, transform: titleDropdownState ? 'scaleY(1)' : 'scaleY(0)' }}>
                          {userTitles && userTitles.length > 0 && userTitles.map((title) => (
                            <div className="profile-title-dropdown-item" onClick={() => changeUserTitle(title.id)}>
                              <p className="main-font-semi-bold font-size-12 font-color-black">{title.title}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Voornaam</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <input className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable" value={userData?.firstName || ''} onChange={(event) => handleFieldEdit('firstName', event.target.value)} onBlur={handleFormUpdate} />
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Naam</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <input className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable" value={userData?.lastName || ''} onChange={(event) => handleFieldEdit('lastName', event.target.value)} onBlur={handleFormUpdate} />
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Email</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <input className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable profile-editable-email" value={userData?.email || ''} onChange={(event) => handleFieldEdit('email', event.target.value)} onBlur={handleFormUpdate} />
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Telefoon</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <input className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable" value={userData?.phone || ''} onChange={(event) => handleFieldEdit('phone', event.target.value)} onBlur={handleFormUpdate} />
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Straat & nr.</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <input className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable" value={userData?.street || ''} onChange={(event) => handleFieldEdit('street', event.target.value)} onBlur={handleFormUpdate} />
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Postcode & Woonpl.</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <input className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable-zip" value={userData?.zip || ''} onChange={(event) => handleFieldEdit('zip', event.target.value)} onBlur={handleFormUpdate} />
                      <input className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable" value={userData?.city || ''} onChange={(event) => handleFieldEdit('city', event.target.value)} onBlur={handleFormUpdate} />
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Wachtwoord</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <div className="profile-details-password-container">
                        <input type={`${passwordState ? 'text' : 'password'}`} disabled className="main-font-semi-bold font-size-14 font-color-black profile-details-password" value="1234567" />
                        <div className="profile-details-password-show flex-shrink-disable margin-right-10 relative" onClick={() => setPasswordState(!passwordState)}>
                          {/* <img src={require('../../../../../assets/images/icon/see_darkbleu.svg')} alt="eye" className="height-auto width-auto object-fit-contain absolute animatable-400 ease-in-out-quart" style={{ opacity: passwordState ? 0 : 1 }} />
                          <img src={require('../../../../../assets/images/icon/see_no_darkbleu.svg')} alt="eye" className="height-auto width-auto object-fit-contain absolute animatable-400 ease-in-out-quart" style={{ opacity: passwordState ? 1 : 0 }} /> */}
                        </div>
                        <button className="profile-details-password-renew center-content flex-shrink-disable animatable-400 ease-in-out-quart cursor-pointer" onClick={() => setRenewPasswordModalState(true)}>
                          <p className="main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart">Wijzigen</p>
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">Extra info</p>
                    </Grid>
                    <Grid container item sm={6} md={9}>
                      <textarea className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-textarea" value={userData?.comments || ''} onChange={(event) => handleFieldEdit('comments', event.target.value)} onBlur={handleFormUpdate} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <SpaceRow top="42" />
              <Grid container>
                <p className="main-font-bold font-size-20 font-color-black">Voorkeuren</p>
              </Grid>
              <SpaceRow top="15" />
              <Grid container>
                <div className="profile-preferences-container">
                  <Grid container>
                    <Grid container item sm={6} md={3} direction="column">
                      <p className="main-font-regular font-size-14 font-color-grey">Communicatie</p>
                      <p className="main-font-regular font-size-14 font-color-grey">Facturatie</p>
                    </Grid>
                    <Grid container item direction="column" sm={6} md={9}>
                      <div className="flex-row vertical-center">
                        <CheckboxRound checkState={checkStateOne} setCheckState={setCheckStateOne} onClick={(value) => handleCheckboxUpdate(value, 1)} />
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9">Gewone zending</p>
                      </div>
                      <SpaceRow top="5" />
                      <div className="flex-row vertical-center">
                        <CheckboxRound checkState={!checkStateOne} setCheckState={setCheckStateOne} onClick={(value) => handleCheckboxUpdate(!value, 1)} secondary />
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9">Email</p>
                      </div>
                    </Grid>
                  </Grid>
                  <SpaceRow top="15" />
                  <Grid container>
                    <Grid container item sm={6} md={3}>
                      <p className="main-font-regular font-size-14 font-color-grey">
                        Communicatie
                        <br />
                        Beheer
                      </p>
                    </Grid>
                    <Grid container item direction="column" sm={6} md={9}>
                      <div className="flex-row vertical-center">
                        <CheckboxRound checkState={checkStateTwo} setCheckState={setCheckStateTwo} onClick={(value) => handleCheckboxUpdate(value, 2)} />
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9">Aangetekende zending</p>
                      </div>
                      <SpaceRow top="5" />
                      <div className="flex-row vertical-center">
                        <CheckboxRound checkState={!checkStateTwo} setCheckState={setCheckStateTwo} onClick={(value) => handleCheckboxUpdate(!value, 2)} secondary />
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9">Gewone zending</p>
                      </div>
                      <SpaceRow top="5" />
                      <div className="flex-row vertical-center">
                        <Checkbox initialState={userData?.preference_communication_email} onChange={handleEmailCheckbox} />
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-left-9">Email</p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <SpaceRow top="42" />
            </Grid>
            <Grid container direction="column" item sm={6}>
              <Grid container>
                <p className="main-font-bold font-size-20 font-color-black">Mijn aankoop</p>
              </Grid>
              <SpaceRow top="15" />
              <Grid container>
                <div className="profile-purchases-container">
                  {buyerList && buyerList.length > 0 && buyerList.map((buyer, buyerIndex) => (
                    <>
                      <div onClick={() => setActiveBuyerModal(buyerIndex + 1)}>
                        <PurchaseInfo data={buyer} toggleVisibility={setOwnerModalState} secondary={buyerIndex !== 0} userData={userData} />
                        <SpaceRow top="30" />
                      </div>
                    </>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smUp>
        <Footer />
      </Hidden>
      <ErrorModal ref = {errorModalRef} />
    </div>
  );
}
