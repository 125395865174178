import React, { useState, useEffect } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import PropTypes from "prop-types";

import './error-message.scss';

export default function ErrorMessage({
  error,
  resetErrorBoundary,
  isOpen
}) {
  const [openDialogState, setOpenDialogState] = useState(false);

  useEffect(() => {
    if(isOpen) {
      setOpenDialogState(isOpen)
    }
  }, [isOpen])

  return (
    <Dialog open={openDialogState} onClose={() => setOpenDialogState(false)}>
      <div className="error-message-container">
        <Grid container justify="center">
          <div className="flex-row width-auto horizontal-center">
            <p className="main-font-semi-bold font-size-14 font-color-black">
              An
              error occured:
            </p>
          </div>
          <div className="flex-row width-auto horizontal-center">
            <p
              className="main-font-semi-bold font-size-14 font-color-black center-text"
            >
              {error?.message}
            </p>
          </div>
          <div className="flex-row width-auto horizontal-center">
            <button
              type="button"
              className="error-message-button center-content"
              onClick={() => {
                setOpenDialogState(false);
                resetErrorBoundary();
              }}
            >
              <p
                className="main-font-semi-bold font-size-14 font-color-white"
              >
                Okay
              </p>
            </button>
          </div>
        </Grid>
      </div>
    </Dialog>
  );
}

ErrorMessage.propTypes = {
  isOpen: PropTypes.bool,
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
}

ErrorMessage.defaultProps = {
  isOpen: false,
  error: {
    
  },
  resetErrorBoundary: () => {}
}
