import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { updateDocument } from '../../../common/api/document.api';

const DocBtn = ({
  showStatus,
  documentStatus,
  ShowConfirmModal,
  documentID,
  ShowSligingCard,
  setIsFetching,
  documentUpdatedAt,
  setToggleMessage,
  hasAccess,
  triggerManualUpdate
}) => {
  const [ formatdocumentUpdatedAt, setFormatdocumentUpdatedAt ] = useState(null);
  const DisapproveDoc = (docId) => {
    
    const payload = { isNew: false, status: '3', statusText: 'Het document werd geweigerd' };
    updateDocument(docId, payload, setIsFetching).then((data) => {
      ShowSligingCard();
      setIsFetching(true);
      triggerManualUpdate();
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    if(documentUpdatedAt) {
      const date = documentUpdatedAt ? new Date(documentUpdatedAt) : null;
      setFormatdocumentUpdatedAt(convertDate(date));
    }
  }, [documentUpdatedAt])

  function convertDate(date) {
    if(date) {
      const localTimezone = 0; //date.getTimezoneOffset()/60
      const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() - localTimezone, date.getMinutes())
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear()
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();
      return `op an ${day.toString().length === 1 ? '0' + day : day}-${month.toString().length === 1 ? '0' + month : month}-${year} aan ${hours.toString().length === 1 ? '0' + hours : hours}:${minutes.toString().length === 1 ? '0' + minutes : minutes}`
    }
  }

  return (
    <Grid container className=" centerAlign fixed">
      <Grid container>

        <Grid item xs={12}>
          <button
            className="btn-lightGray btn-content w-100 main-font-semi-bold vertical-center"
            onClick={() => setToggleMessage(true)}
          >
            <img
              alt="preview"
              src={require('../../../assets/images/icon/chat_darkbleu.svg')}
              className="header-icons margin-right-18"
            />
            <span className="text-center">Informatie aanvragen</span>
          </button>
        </Grid>
        {showStatus && (documentStatus === 0 || documentStatus === 2) ? (
          <>
            {hasAccess && (
              <>
                <Grid containeritem xs={12}>
                  <button
                    onClick={() => ShowConfirmModal()}
                    type="button"
                    className=" btn-green btn-content  main-font-semi-bold vertical-center"
                  >
                    <img
                      alt="preview"
                      src={require('../../../assets/images/iconround/check_white.svg')}
                      className="header-icons margin-right-18"
                    />
                    <span className="text-center">Goedkeuren</span>
                  </button>
                </Grid>
                <Grid item xs={12}>
                  <button
                    type="button"
                    className="  btn-red-outline btn-content  main-font-semi-bold vertical-center cursor-pointer"
                    onClick={() => DisapproveDoc(documentID)}
                  >
                    <span className="text-center">Weigeren</span>
                  </button>
                </Grid>
              </>
            )}
          </>
        ) : ''}
        {showStatus && documentStatus === 3 ? (
          <>
            <Grid container item xs={12}>
              <div className="
                            relative
                            btn-fail
                            btn-content
                            main-font-semi-bold
                            vertical-center"
              >
                <img
                  alt="preview"
                  src={require('../../../assets/images/iconround/cross_white.svg')}
                  className="header-icons margin-right-18"
                />
                <div className="center-text  flex-column horizontal-center width-auto">
                  <div>Geweigerd</div>
                  <br />
                  <div>
                    {' '}
                    {formatdocumentUpdatedAt}
                  </div>
                </div>
              </div>
            </Grid>
          </>
        ) : ''}
        {showStatus && documentStatus === 1 ? (
          <>
            <Grid container item xs={12}>
              <div className="
                            relative
                            btn-success
                            btn-content
                            main-font-semi-bold
                            vertical-center"
              >
                <img
                  alt="preview"
                  src={require('../../../assets/images/iconround/check_white.svg')}
                  className="header-icons absolute margin-right-18"
                />
                <div className="center-text  flex-column horizontal-center width-auto ">
                  <div>Goedkeuren</div>
                  <br />
                  <div>
                    {' '}
                    {formatdocumentUpdatedAt}
                  </div>
                </div>
              </div>
            </Grid>
          </>
        ) : ''}
      </Grid>
    </Grid>

  );
};
DocBtn.defaultProps = {
  ShowConfirmModal: () => { return false; },
  documentStatus: false,
  showStatus: false,
};
DocBtn.propTypes = {
  ShowConfirmModal: PropTypes.func,
  documentStatus: PropTypes.bool,
  showStatus: PropTypes.bool,
};
export default DocBtn;
