import React, { useEffect, useState } from 'react';
import { Hidden } from '@material-ui/core';

import DashboardMenu from './menu/ProjectsMenu.view';

import Gebouw from './partials/Gebouw.view';
import Aankoop from './partials/Aankoop.view';
import PlannenAankoop from './partials/PlannenAankoop.view';
import Afwerking from './partials/Afwerking.view';
import Facturatie from './partials/Facturatie.view';
import Beheer from './partials/Beheer.view';
import Footer from '../../footer/Footer';

import './projects.scss';
import { getDocumentsByUnits, getDocumentsByProject } from '../../../../../common/api/routes';
import { getChoicesList, getProjectDocuments } from './projects.api';
import { getProjectById } from "../../../../../common/api/routes";
import Spinner from '../../../../../components/spinner/Spinner';
import { SpaceRow } from '../../../../../components/space/Space';
import axios from 'axios';

const Dashboard = (props) => {
  const [selectedCategory, setSelectedCategory] = useState('1');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isFetching, setIsFetching] = useState(false);
  const [choices, setChoices] = useState([]);
  const [ buyerData, setBuyerData ] = useState({});
  const [ projectDocuments, setProjectDocuments ] = useState([])
  const [ manualUpdate, setManualUpdate ] = useState(false);

  const buyerId = localStorage.getItem('selected-project');
  const userData = JSON.parse(localStorage.getItem('user'))
  const { projectData, loadedDocument, setLoadedDocument } = props;

  useEffect(() => {
    getChoicesList(setIsFetching, setChoices);
  }, []);

  useEffect(() => {
    if(buyerId) {
      axios.get(getProjectById(buyerId)).then(response => {
        setBuyerData(response.data.data);
      }).catch(error => console.log(error))
    }
  }, [buyerId])

  useEffect(() => {
    if(buyerData?.project?.id) {
      // compile the units into a string using the buyerData
      let unitList = "";
      buyerData.user_units.forEach((unit, index) => {
        if(unit?.units?.id) {
          unitList = unitList + unit.units.id;
          if(index !== (buyerData.user_units.length - 1)) {
            unitList = unitList + ",";
          }
        }
      })
      let documentList = [];
      axios.get(getDocumentsByUnits(1, userData.id, buyerData.project.id, "")).then(response => {

        documentList = response.data.data;
        axios.get(getDocumentsByUnits(0, userData.id, buyerData.project.id, unitList)).then(response => {
          documentList = [...documentList, ...response.data.data];
          setProjectDocuments(documentList)
        }).catch(error => console.log(error))

      }).catch(error => console.log(error))
    }
  }, [buyerData, manualUpdate])

  useEffect(() => {
    if(loadedDocument?.id) {
      setSelectedCategory(loadedDocument.category_id)
    }
  }, [loadedDocument])

  const updateSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [screenWidth]);

  const triggerManualUpdate = () => {
    setManualUpdate(!manualUpdate);
  }

  return (
    <div
      className="dashboard-container flex-column background-color-faded-faint-grey"
    >
      {isFetching ? (
        <div className="text-center padding-top-20">
          <Spinner text="Fetching Data..." />
        </div>
      ) : (
        <>
          <div id="choices" className="width-auto fade-in-translate">
            <div
              className="flex-row width-auto fade-in-left"
              style={{ marginLeft: -7 }}
            >

              <DashboardMenu
                choices={choices}
                screenWidth={screenWidth}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />

            </div>
          </div>

          <div id="choices-section" className="width-auto">
            <p
              className="font-size-20 vietnam-bold line-height-29 font-color-black fade-in-translate"
            >
              Documenten
            </p>
            <div style={screenWidth < 959 ? { marginLeft: 15 } : {}}>
              {selectedCategory === '1' && (
                <Gebouw selectedCategory={selectedCategory} data = {projectDocuments.filter(document => document.category_id == 1)} projectData = {projectData} triggerManualUpdate = {triggerManualUpdate} loadedDocument = {loadedDocument} setLoadedDocument = {setLoadedDocument} />)}
              {selectedCategory === '2' && (
                <Aankoop selectedCategory={selectedCategory} data = {projectDocuments.filter(document => document.category_id == 2)} projectData = {projectData} triggerManualUpdate = {triggerManualUpdate} loadedDocument = {loadedDocument} setLoadedDocument = {setLoadedDocument} />)}
              {selectedCategory === '3' && (
                <PlannenAankoop selectedCategory={selectedCategory} data = {projectDocuments.filter(document => document.category_id == 3)} projectData = {projectData} triggerManualUpdate = {triggerManualUpdate} loadedDocument = {loadedDocument} setLoadedDocument = {setLoadedDocument} />)}
              {selectedCategory === '4' && (
                <Afwerking selectedCategory={selectedCategory} data = {projectDocuments.filter(document => document.category_id == 4 || document.category.parent_id == 4)} projectData = {projectData} triggerManualUpdate = {triggerManualUpdate} loadedDocument = {loadedDocument} setLoadedDocument = {setLoadedDocument} />)}
              {selectedCategory === '5' && (
                <Facturatie selectedCategory={selectedCategory} data = {projectDocuments.filter(document => document.category_id == 5)} projectData = {projectData} triggerManualUpdate = {triggerManualUpdate} loadedDocument = {loadedDocument} setLoadedDocument = {setLoadedDocument} />)}
              {selectedCategory === '6' && (
                <Beheer selectedCategory={selectedCategory} data = {projectDocuments.filter(document => document.category_id == 6)} projectData = {projectData} triggerManualUpdate = {triggerManualUpdate} loadedDocument = {loadedDocument} setLoadedDocument = {setLoadedDocument} />)}
            </div>

          </div>
        </>
      )}
      <Hidden smUp>
        <>
          <SpaceRow top="16" />
          <Footer />
        </>
      </Hidden>
    </div>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
