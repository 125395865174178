/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import {
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Modal from '../modal/modal';
import './_sliding_card.scss';
import SlidingCard from './sliding_card';
import DocInfo from './DocInfo';
import DocHeader from './DocHeader';
import DocBtn from './DocBtn';
import MessagePanel from '../../../views/dashboard/layout/content/sidecard/messages/message_panel/message_panel';
import SlideCardHeader from '../../../views/dashboard/layout/content/sidecard/SideCardHeader';

const DocSlideCard = ({
  isOpen,
  ShowSligingCard,
  document, buyerId,
  showStatus,
  setIsFetching,
  hasAccess,
  triggerManualUpdate
}) => {
  const [toggle, setToggle] = useState(false);
  const [toggleMessage, setToggleMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setToggleMessage(false);
    }, 500);
  }, [ShowSligingCard]);

  const ShowConfirmModal = () => {
    const state = !toggle;
    setToggle(state);
    return state;
  };

  function grabFileExtension(file) {
    if(file) {
      const fileString = file.split(".");
      const extension = fileString[fileString.length - 1];
      if(extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "bmp") {
        return "img";
      }
      else if(extension === "pdf") {
        return "pdf";
      }
      else if(extension === "doc" || extension === "docx") {
        return "doc";
      }
      else if(extension === "xls" || extension === "xlsx") {
        return "xls";
      }
      else {
        return "other";
      }
    }
    else {
      return null;
    }
  }

  return (
    <SlidingCard
      isOpen={isOpen}
      ShowSligingCard={ShowSligingCard}
    >
      <Grid container className="DrawerContainer centerAlign">
        <img
          src={require('../../../assets/images/icon/cross_gray.svg')}
          alt="close"
          onClick={() => ShowSligingCard()}
          className="cursor-pointer close-btn"
        />
        <img
          src={require('../../../assets/images/iconround/back_gray.svg')}
          alt="back"
          onClick={() => ShowSligingCard()}
          className="cursor-pointer back-btn"
        />
        {!toggleMessage
          ? (
            <>
              <DocHeader documentName={document.filename} documentType={grabFileExtension(document.filename)} doc={document} buyerId = {buyerId} />
              <div className="ContentContainer">
                <Grid container direction="column" style={{ height: '98%' }} wrap="nowrap" justify="space-between">
                  <Grid container direction="column">
                    <DocInfo doc={document} />
                  </Grid>
                  <DocBtn
                    showStatus={showStatus}
                    documentID={document.id}
                    documentUpdatedAt={document.updated_at}
                    setIsFetching={setIsFetching}
                    documentStatus={document.status}
                    ShowSligingCard={ShowSligingCard}
                    ShowConfirmModal={ShowConfirmModal}
                    setToggleMessage={setToggleMessage}
                    hasAccess = {hasAccess}
                    triggerManualUpdate = {triggerManualUpdate}
                  />
                </Grid>
              </div>
            </>
          )
          : (
            <>
              <SlideCardHeader
                title="Berichten"
                headerImage={require('../../../assets/images/icon/chat_darkbleu.svg')}
              />
              <Grid container>
                <MessagePanel documentId = {document.id} buyerId={buyerId} hasAccess = {hasAccess} />
              </Grid>
            </>
          )}
      </Grid>
      <Modal isOpen={toggle} ShowConfirmModal={ShowConfirmModal} ShowSligingCard={ShowSligingCard} setIsFetching={setIsFetching} docId={document.id} triggerManualUpdate = {triggerManualUpdate} documentName = {document.filename} />
    </SlidingCard>
  );
};
DocSlideCard.defaultProps = {
  isOpen: false,
  ShowSligingCard: () => { return false; },
  document: {},
  showStatus: false,
};

DocSlideCard.propTypes = {
  document: PropTypes.object,
  ShowSligingCard: PropTypes.func,
  isOpen: PropTypes.bool,
  showStatus: PropTypes.bool,
};
export default DocSlideCard;
