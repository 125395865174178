import React from 'react';
import { upperCase } from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import './projectsMenu.scss';

const DashboardMenu = ({
  choices,
  screenWidth,
  selectedCategory,
  setSelectedCategory,
}) => {
  const handleMenuClick = (selectedIndex) => {
    setSelectedCategory(selectedIndex);
  };

  const getChoiceImage = (category) => {
    // eslint-disable-next-line import/no-dynamic-require
    return require(`../../../../../../assets/images/pictures/${category}.svg`);
  };

  return (
    <Grid
      container
      style={
        screenWidth < 730 ? {
          maxWidth: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        } : {
          maxWidth: '90%',
        }
      }
    >
      {(choices && choices.length > 0) ? (
        <>
          {choices.map((selected) => (
            <div
              key={`${selected.id} ${selected.title}`}
              className={`padding-left-5 padding-right-5 ${screenWidth < 410 ? 'padding-top-5' : 'padding-top-20'}`}
            >
              <div
                onClick={() => handleMenuClick(selected.id)}
                className={`menu-item menu-item-${selected.title} ${selectedCategory === selected.id && `${selected.title}-selected`}`}
              >
                <span
                  className={`image b-border border-right ${selectedCategory === selected.id ? `${selected.title}-selected` : selected.title}`}
                >
                  <img
                    width={30}
                    height={30}
                    alt="category"
                    src={getChoiceImage(selected.title)}
                  />
                </span>
                <span className={`text`}>
                  {upperCase(selected.title === 'plannen_aankoop' ? 'plannen' : selected.title)}
                </span>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="text-center padding-top-20">
          No categories found.
        </div>
      )}
    </Grid>
  );
};

DashboardMenu.propTypes = {
  choices: PropTypes.array.isRequired,
  screenWidth: PropTypes.number.isRequired,
  selectedCategory: PropTypes.number.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
};

export default DashboardMenu;
