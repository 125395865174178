import { get } from 'lodash';

import request from '../../common/request';
import storage from '../../common/storage';

export const getUserProjects = (setFetching, setResponse) => {
  setFetching(true);

  const userId = get(storage.get('user'), 'id') || 2;
  const response = request.get(`/user/project/${userId}`);

  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
    })
    .catch(() => {
      setFetching(false);
    });
};
