import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  btn: {
    height: 28,
    margin: '10px 20px',
    position: 'absolute',
    right: '0px',
    marginTop: 10,
  },
  centerAlign: {
    textAlign: 'center',
    margin: '0px auto',
  },
  avatar: {
    width: '33px',
    height: '33px',
    marginTop: '10px',
    margin: 'inherit',
    paddingLeft: 15,
  },
}));

const SlideCardHeader = ({
  title,
  headerImage,
  toggleVisibility,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={5} />
      <Grid item xs={2}>
        <Avatar
          alt="extension"
          variant="rounded"
          src={headerImage}
          className={classes.avatar}
        />
      </Grid>
      <Grid item xs={5} />


      <Grid item xs={12} style={{ textAlign: 'center', paddingTop: 15 }}>
        <h2 className="title main-font main-font-bold text-center">
          {title}
        </h2>
      </Grid>
    </>
  );
};

SlideCardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  headerImage: PropTypes.string.isRequired,
};

export default SlideCardHeader;
