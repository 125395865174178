const Currencies = {
  'en-US': 'USD',
};

const getLanguage = () => {
  // supports en-US only for now
  return 'en-US';
};

const formatNumber = (number, fractions, style) => {
  const language = getLanguage();
  const defaultStyle = style || 'decimal';
  let retValue;
  if (number && fractions) {
    retValue = new Intl.NumberFormat(language, {
      style: defaultStyle,
      currency: Currencies[language],
      minimumFractionDigits: fractions,
    }).format(number);
  } else if (number && !fractions) {
    retValue = new Intl.NumberFormat(language, {
      style: defaultStyle,
      currency: Currencies[language],
    }).format(number);
  } else {
    retValue = new Intl.NumberFormat(language, {
      style: defaultStyle,
      currency: Currencies[language],
      minimumFractionDigits: 2,
    }).format(0);
  }
  return retValue;
};

export const SQLDateToString = (stringDate) => {
  if(typeof stringDate === "string") {
    let newDate = stringDate.substring(0, 10);
    newDate = newDate.split("-");
    newDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
    return newDate
  }
  else {
    return "";
  }
}

export const SQLDateTimeToString = (stringDate) => {
  if(typeof stringDate === "string") {
    let timezone = new Date().getTimezoneOffset();
    timezone = timezone/60;
    let newDateTime = stringDate.split("T");
    let date = newDateTime[0].split("-")
    date.reverse();
    date = date.toString();
    date = date.replace(/,/g, "/");
    let time = newDateTime[1].substring(0, 5);
    time = time.split(":")
    return `${date} - ${parseInt(time[0]) - (timezone)}:${time[1]}`;
  }
  else {
    return "";
  }
}

export const getDutchMonthName = (stringDate) => {
  if(typeof stringDate === "string") {
    let newDate = stringDate.substring(0, 10);
    newDate = newDate.split("-");
    let newDateObject = new Date(newDate[0].toString(), newDate[1].toString() - 1, newDate[2].toString());
    let monthName = "Januari";
    if(newDateObject.getMonth() === 0) {
      monthName = "Januari";
    }
    else if(newDateObject.getMonth() === 1) {
      monthName = "Februari";
    }
    else if(newDateObject.getMonth() === 2) {
      monthName = "Februari";
    }
    else if(newDateObject.getMonth() === 3) {
      monthName = "Maart";
    }
    else if(newDateObject.getMonth() === 4) {
      monthName = "April";
    }
    else if(newDateObject.getMonth() === 5) {
      monthName = "Mei";
    }
    else if(newDateObject.getMonth() === 6) {
      monthName = "Juni";
    }
    else if(newDateObject.getMonth() === 7) {
      monthName = "Juli";
    }
    else if(newDateObject.getMonth() === 8) {
      monthName = "Augustus";
    }
    else if(newDateObject.getMonth() === 9) {
      monthName = "September";
    }
    else if(newDateObject.getMonth() === 10) {
      monthName = "Oktober";
    }
    else if(newDateObject.getMonth() === 11) {
      monthName = "November";
    }
    else if(newDateObject.getMonth() === 12) {
      monthName = "December";
    }
    return newDateObject.getDate() + " " + monthName + " " + newDateObject.getFullYear();
  }
  else {
    return "";
  }
}

export default formatNumber;
