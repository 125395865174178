import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import { Checkbox } from '../../checkbox/Checkbox';
import { getDocument, updateDocument } from '../../../common/api/document.api';
import './_modal.scss';

export default function Modal(props) {
  const [isFetching, setIsFetching] = useState(false);
  const [checkboxState, setCheckboxState] = useState(false);
  const [updatedAt, setupdatedAt] = useState(null);
  
  const user = JSON.parse(localStorage.getItem('user'));

  const clickhandler = () => {
    if (checkboxState) {
      const payload = { isNew: false, status: '1', statusText: 'Het document is goedgekeurd' };
      updateDocument(props.docId, payload, setIsFetching).then((data) => {
        const datetime = moment(data.updatedAt).toISOString();
        props.setIsFetching(true);
        setupdatedAt(datetime);
        props.triggerManualUpdate();
        setTimeout(
          () => {
            props.ShowConfirmModal();
            props.ShowSligingCard();
          }, 2000,
        );
      }).catch((err) => console.log(err));
    }
  };

  function convertDate(sqlDate) {
    if(sqlDate) {
      const date = new Date(sqlDate)
      const localTimezone = 0; // date.getTimezoneOffset()/60
      const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() - localTimezone, date.getMinutes())
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear()
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();
      return `op an ${day.toString().length === 1 ? '0' + day : day}-${month.toString().length === 1 ? '0' + month : month}-${year} aan ${hours.toString().length === 1 ? '0' + hours : hours}:${minutes.toString().length === 1 ? '0' + minutes : minutes}`
    }
  }

  return (
    <Dialog style={{ zIndex: 999999 }} open={props.isOpen} onClose={() => props.ShowConfirmModal()}>
      <div className="padding-24  confirmation-modal">
        <Grid container justify="center">
          <div className="flex-row horizontal-center ">
            <img
              alt="icon"
              className="padding-24"
              src={require('../../../assets/images/icon/file_blue.svg')}
            />
          </div>
          <div className="flex-column">
            <p
              className="main-font-semi-bold font-size-14 font-color-darkBleu"
            >
              Namens {user.firstName} {user.lastName} ondertekent u onderstaand document voor akkoord.
              U bevestigt dat u akkoord gaat met de inhoud ervan. U ontvangt ook een bevestigingsmail.
            </p>
          </div>
          <div className="flex-row margin-top-15 margin-bottom-75">
            <Checkbox className="margin-right-20" onClick={() => { setCheckboxState(!checkboxState); }} />
            <p className="main-font-semi-bold  margin-left-15 margin-top-10 font-size-14 font-color-darkBleu">
              Ik heb het volgende document en de
              {' '}
              <a href="/dashboard/terms" className="link-underline-darkBlue">algemene voorwaarden</a>
              {' '}
              gelezen en ga ermee akkoord
            </p>
          </div>
          <p className = "main-font-regular font-size-14 font-color-darkBleu">{props.documentName}</p>
          <div className=" flex-column width-auto vertical-center padding-bottom-30">
            <button
              onClick={() => {
                clickhandler();
              }}
              type="button"
              className="btn-green btn-content main-font-semi-bold vertical-center relative"
              style = {{width: '317px'}}
            >
              <img alt="preview" src={require('../../../assets/images/iconround/check_white.svg')} className="header-icons margin-right-18 absolute" />
              <span className="text-center"> Goedkeuren</span>
            </button>

            <p className="main-font-regular font-size-14 font-color-darkBleu" style={{ visibility: updatedAt ? 'visible' : 'hidden'}}>
              Goedkeuren
              {' '}
              {convertDate(updatedAt)}
            </p>

          </div>
        </Grid>
      </div>
    </Dialog>
  );
}
