import { Grid, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { SpaceRow } from '../../components/space/Space';

import './chooseProject.scss';
import { getUserProjects } from './chooseProject.api';
import Spinner from '../../components/spinner/Spinner';

const ChooseProject = () => {
  const [projects, setProjects] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    getUserProjects(setIsFetching, setProjects);
  }, []);

  return (
    <Grid container>
      <div className="container-full">
        <div className="login-background center-content">

          <Container maxWidth="lg">
            <Grid container justify="center">

              <div className="login-box-container center-content relative">
                <div className="login-box-layer" />
                <div className="login-box vertical-center relative">

                  <Grid container justify="center">
                    <img
                      src={require('../../assets/images/logo/logo_white.png')}
                      className="login-logo"
                      alt="logo"
                    />
                  </Grid>
                  <SpaceRow top="45" />

                  {isFetching ? (
                    <div className="text-center" style={{ color: '#fff' }}>
                      <Spinner text="Fetching Projects..." />
                    </div>
                  ) : (
                    <>
                      <Grid container>
                        <Grid item xs={1}>
                          <img
                            height={30}
                            src={require('../../assets/images/pictures/gebouw.svg')}
                            alt="logo"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={11}
                          style={{
                            fontSize: 20,
                            paddingLeft: 10,
                            color: '#fff',
                            fontWeight: 500,
                          }}
                        >
                          Kies uw project
                        </Grid>
                      </Grid>
                      <SpaceRow top="20" />

                      <Grid container>
                        {projects && projects.length ? (
                          projects.map((p) => (
                            <Grid container xs={12} className="project-heading">
                              <Grid item xs={1}>
                                <div className={get(p, 'project.unreadMessages') ? 'messagesDot' : ''}>
                                  {get(p, 'project.unreadMessages') > 0 && get(p, 'project.unreadMessages')}
                                </div>
                              </Grid>
                              <Grid item xs={11} className="project-title">
                                <Link to={`/dashboard/projects/${p.id}`} style={{ color: 'inherit' }}>
                                  {p.project.name}
                                </Link>
                              </Grid>
                            </Grid>
                          ))
                        ) : (
                          <div className="text-center" style={{ color: '#fff' }}>
                            No Projects found.
                          </div>
                        )}
                      </Grid>
                      <SpaceRow top="45" />
                    </>
                  )}

                </div>
              </div>

            </Grid>
          </Container>

        </div>
      </div>
    </Grid>
  );
};

export default ChooseProject;
