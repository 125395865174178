import React, { useEffect, useState, useRef } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import axios from "axios";

import { useLocation, useHistory } from 'react-router-dom';

import { SpaceRow } from '../../../components/space/Space';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { login, loginWithToken } from "../../../common/api/routes";
import Loader from "../../../components/loader/loader";
import ErrorMessage from "../../../components/error-message/ErrorMessage";

import "../login.scss";

export default function Login(props) {
    const [emailState, setEmailState] = useState('');
    const [passwordState, setPasswordState] = useState('');
    const [ loadState, setLoadState ] = useState(false);
    const [ lastErrorState, setLastErrorState ] = useState(null);
    const [ animationState, setAnimationState ] = useState(false);

    const [emailStateError, setEmailStateError] = useState(false);
    const [passwordStateError, setPasswordStateError] = useState(false);

    const { changePhase } = props;
    const emailCheckRef = useRef(0);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [])


    useEffect(() => {
        const query = new URLSearchParams(location.search)
        console.log("==>",query.get("token"))
        console.log("==>",location)

        if(query.get("token")) {
            const token = location.search.substring(1, location.search.length);
            console.log("==> token exist")
            console.log(token)
            if(query.get("user") === "true") {
                changePhase(2);
            }
            else {
                axios.get(loginWithToken(query.get("token"))).then(response => {
                    // if we get a response that means it was a valid token otherwise go through normal login process
                    if(response.data.data.id) {
                        const userData = JSON.stringify(response.data.data);
                        localStorage.setItem('user', userData);
                        setLoadState(false)
                        setAnimationState(true);
                        setTimeout(() => {
                            // we set a delay of 400 before changing routes so that the animation has time to complete 
                            changePhase(3);
                        }, 500)
                    }
                }).catch(error => console.log(error))
            }
        }else{
            console.log("==> token not exist")
        }
    }, [location])

    const handleEmailChange = (event) => {
        if (event.target.validity.valid) {
        setEmailStateError(false);
        } else {
        setEmailStateError(true);
        }
        setEmailState(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPasswordStateError(false);
        setPasswordState(event.target.value);
    };

    const isValid = () => {
        let validity = true;
        if (emailState === '' || emailStateError) {
            validity = false;
            setEmailStateError(true)
        }
        if (passwordState === '' || passwordStateError) {
            validity = false;
            setPasswordStateError(true)
        }
        return validity;
    }; 

    const handleLoginEvent = () => {
        if(emailCheckRef.current.getValue()) {
            if(isValid() && !loadState) {
                setLoadState(true);
                axios.post(login, { 
                    email: emailState,
                    password: passwordState,
                }).then(response => {
                    const userData = JSON.stringify(response.data.data.info);
                    localStorage.setItem('user', userData);
                    setLoadState(false)
                    setAnimationState(true);
                    setTimeout(() => {
                        // we set a delay of 400 before changing routes so that the animation has time to complete 
                        changePhase(3);
                    }, 500)
                }).catch(error => {
                    setLastErrorState(error.response?.data?.data)
                    setLoadState(false)
                })
            }
        }
        else {
            emailCheckRef.current.showError();
        }
    }

    const handleKeyPress = (event) => {
        if(event.keyCode === 13) {
            handleLoginEvent();
        }
    }

    const stayLoggedIn = () => {
        localStorage.setItem('stayLoggedIn', true);
    }

    const removeStayLoggedIn = () => {
        localStorage.setItem('stayLoggedIn', false);
    }

    return(
        <Grid container className = "animatable-400 ease-in-out-quart" style = {{opacity: animationState ? 0 : 1, transform: animationState ? 'translateY(-25px)' : 'translateY(0px)'}} >
            <SpaceRow top="45" />
            <Grid container>
            <div className="login-input-container">
                <div
                className="login-input-container-layer animatable-400 ease-in-out-quart"
                style={{ transform: emailStateError ? 'scaleX(1)' : 'scaleX(0)' }}
                />
                <div className="login-input-container-main">
                <img
                    className="login-input-icon flex-shrink-disable"
                    src={require('../../../assets/images/icon/person_white.svg')}
                    alt="person white"
                />
                <input
                    className="login-input flex-shrink-disable main-font-regular font-size-14 font-color-white"
                    value={emailState}
                    pattern="[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+"
                    placeholder="E-mailadres"
                    onChange={handleEmailChange}
                />
                </div>
            </div>
            </Grid>
            <SpaceRow top="45" />

            <Grid container>
            <div className="login-input-container">
                <div
                className="login-input-container-layer animatable-400 ease-in-out-quart"
                style={{ transform: passwordStateError ? 'scaleX(1)' : 'scaleX(0)' }}
                />
                <div className="login-input-container-main">
                <img
                    className="login-input-icon flex-shrink-disable"
                    src={require('../../../assets/images/icon/lock_white.svg')}
                    alt="person white"
                />
                <input
                    type="password"
                    className="login-input flex-shrink-disable main-font-regular font-size-14 font-color-white"
                    value={passwordState}
                    placeholder="Wachtwoord"
                    onChange={handlePasswordChange}
                />
                </div>
            </div>
            </Grid>
            <SpaceRow top="45" />

            <Grid container justify="space-between" alignItems="center">
            <div className="flex-row vertical-center">
                <Checkbox isActive = {stayLoggedIn} isUnactive = {removeStayLoggedIn} />
                <p
                className="main-font-regular font-size-14 font-color-white margin-left-5"
                >
                Aangemeld blijven
                </p>
            </div>
            <Hidden xsDown>
                <div className="flex-row" onClick = {() => history.push('/forgot_password')}>
                <p
                    className="main-font-regular font-size-14 font-color-white text-underline cursor-pointer"
                >
                    Wachtwoord vergeten?
                </p>
                </div>
            </Hidden>
            <Hidden smUp>
                <div className="flex-row margin-left-37 margin-top-15" onClick = {() => history.push('/forgot_password')}>
                <p
                    className="main-font-regular font-size-14 font-color-white text-underline cursor-pointer"
                >
                    Wachtwoord vergeten?
                </p>
                </div>
            </Hidden>
            </Grid>
            <SpaceRow top="45" />

            <Grid container wrap="nowrap" alignItems="flex-start">
            <Checkbox ref = {emailCheckRef} />
            <p
                className="main-font-regular font-size-14 font-color-white margin-left-5"
            >
                Ik ga akkoord om per e-mail op de hoogte te worden gehouden omtrent wijzigingen en nieuwe documenten.
            </p>
            </Grid>
            <SpaceRow top="45" />

            <Grid container className = "relative">
                <Grid container justify = "center" className = "absolute">
                    <Loader animationState = {loadState} />
                </Grid>
                <button
                    type="button"
                    className="login-button center-content animatable-400 ease-in-out-quart"
                    onClick = {handleLoginEvent}
                    style = {{opacity: loadState ? 0 : 1, transform: loadState ? 'translateY(-15px)' : 'translate(0px)'}}
                >
                    <p
                    className="main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart"
                    >
                        Aanmelden
                    </p>
                </button>
            </Grid>
            <ErrorMessage error = {lastErrorState} isOpen = {lastErrorState !== null ? true : false} />
        </Grid>
    )
}