import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';

import SlidingCard
  from '../../../../../../components/shared/sliding_card/sliding_card';
import SideCardHeader from '../SideCardHeader';
import {SpaceRow} from '../../../../../../components/space/Space';
import {changePassword} from '../../../../../../common/api/routes';

import './renew_password.scss';

export default function RenewPassword(props) {
  const [oldPasswordState, setOldPasswordState] = useState('');
  const [newPasswordState, setNewPasswordState] = useState('');
  const [confirmPasswordState, setConfirmPasswordState] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [oldPasswordStateError, setOldPasswordStateError] = useState(false);
  const [newPasswordStateError, setNewPasswordStateError] = useState(false);
  const [confirmPasswordStateError, setConfirmPasswordStateError] = useState(false);

  const [loadState, setLoadState] = useState(false);
  const [errorState, setLastErrorState] = useState('');
  const [successState, setSuccessState] = useState('');


  const userData = JSON.parse(localStorage.getItem('user'));
  const {isOpen, toggleVisibility} = props;

  const handleOldPassword = (event) => {
    setOldPasswordState(event.target.value);
    setOldPasswordStateError(false);
    setLastErrorState('');
    setSuccessState('');
  };

  const handleNewPassword = (event) => {
    setNewPasswordState(event.target.value);
    setNewPasswordStateError(false);
    setLastErrorState('');
    setSuccessState('');
  };

  const handleConfirmPassword = (event) => {
    setConfirmPasswordState(event.target.value);
    setConfirmPasswordStateError(false);
    setLastErrorState('');
    setSuccessState('');
  };

  const isValid = () => {
    let validation = true;
    if (oldPasswordState === '') {
      validation = false;
      setSuccessState('');
      setLastErrorState('');
      setOldPasswordStateError(true);
    }
    if (newPasswordState === '') {
      validation = false;
      setSuccessState('');
      setLastErrorState('');
      setNewPasswordStateError(true);
    }
    if (confirmPasswordState === '') {
      validation = false;
      setSuccessState('');
      setLastErrorState('');
      setConfirmPasswordStateError(true);
    }
    if (newPasswordState !== confirmPasswordState) {
      validation = false;
      setSuccessState('');
      setLastErrorState('');
      setNewPasswordStateError(true);
      setConfirmPasswordStateError(true);
    }
    return validation;
  };

  const handleButtonSubmit = () => {
    if (isValid() && userData) {
      setLoadState(true);
      axios.post(changePassword, {
        user_id: userData?.id,
        password: oldPasswordState,
        new_password: newPasswordState,
      }).then((res) => {
        setSuccessState(res?.data?.data?.message);
        setLoadState(false);
        setOldPasswordState('');
        setNewPasswordState('');
        setConfirmPasswordState('');
        setLastErrorState('');
      }).catch((error) => {
        setLastErrorState(error?.response?.data?.data?.message);
        setLoadState(false);
      });
    }
  };

  return (
    <SlidingCard isOpen={isOpen} ShowSligingCard={() => toggleVisibility()}>
      <Grid container style={{padding: 20}} className="height-auto">
        <img
          src={require('../../../../../../assets/images/icon/cross_gray.svg')}
          alt="close"
          onClick={() => toggleVisibility()}
          className="cursor-pointer close-btn"
        />
        <img
          src={require('../../../../../../assets/images/iconround/back_gray.svg')}
          alt="back"
          onClick={() => toggleVisibility()}
          className="cursor-pointer back-btn"
        />
        <SideCardHeader
          title="Nieuw wachtwoord"
          toggleVisibility={toggleVisibility}
          headerImage={require('../../../../../../assets/images/icon/lock_darkbleu.svg')}
        />
        <div className="renew-password-container">
          <div className="flex-column width-auto">
            {errorState && (
              <Grid container>
                <Grid item md={12} style={{ textAlign: 'center' }}>
                  <p
                    className="animatable-400 ease-in-out-quart"
                    style={{ color: 'red' }}
                  >
                    {errorState}
                  </p>
                </Grid>
              </Grid>
            )}
            {successState && (
              <Grid container>
                <Grid item md={12} style={{ textAlign: 'center' }}>
                  <p
                    className="animatable-400 ease-in-out-quart"
                    style={{ color: 'green' }}
                  >
                    {successState}
                  </p>
                </Grid>
              </Grid>
            )}

            <SpaceRow top="42"/>
            <Grid container alignItems="center">
              <Grid container item xs={6}>
                <p
                  className="main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart"
                  style={{color: oldPasswordStateError ? 'red' : '#9b9b9b'}}>Huidig
                  wachtwoord</p>
              </Grid>
              <Grid container item xs={6}>
                <div className="flex-row width-auto horizontal-between">
                  <input
                    className="renew-password-input main-font-semi-bold font-size-14 font-color-black"
                    type={showOldPassword ? 'text' : 'password'}
                    value={oldPasswordState} onChange={handleOldPassword}/>
                  <button type="button" className="renew-password-eye-container"
                          onClick={() => setShowOldPassword(!showOldPassword)}>
                    <img
                      src={require('../../../../../../assets/images/icon/see_darkbleu.svg')}
                      alt="eye" className="see_darkbleu"
                      className="renew-password-eye animatable-400 ease-in-out-quart"
                      style={{opacity: showOldPassword ? 0 : 1}}/>
                    <img
                      src={require('../../../../../../assets/images/icon/see_no_darkbleu.svg')}
                      alt="eye" className="see_darkbleu"
                      className="renew-password-eye animatable-400 ease-in-out-quart"
                      style={{opacity: showOldPassword ? 1 : 0}}/>
                  </button>
                </div>
              </Grid>
            </Grid>
            <SpaceRow top="20"/>
            <Grid container alignItems="center">
              <Grid container item xs={6}>
                <p
                  className="main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart"
                  style={{color: newPasswordStateError ? 'red' : '#9b9b9b'}}>Nieuw
                  wachtwoord</p>
              </Grid>
              <Grid container item xs={6}>
                <div className="flex-row width-auto horizontal-between">
                  <input
                    className="renew-password-input main-font-semi-bold font-size-14 font-color-black"
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPasswordState} onChange={handleNewPassword}/>
                  <button type="button" className="renew-password-eye-container"
                          onClick={() => setShowNewPassword(!showNewPassword)}>
                    <img
                      src={require('../../../../../../assets/images/icon/see_darkbleu.svg')}
                      alt="eye" className="see_darkbleu"
                      className="renew-password-eye animatable-400 ease-in-out-quart"
                      style={{opacity: showNewPassword ? 0 : 1}}/>
                    <img
                      src={require('../../../../../../assets/images/icon/see_no_darkbleu.svg')}
                      alt="eye" className="see_darkbleu"
                      className="renew-password-eye animatable-400 ease-in-out-quart"
                      style={{opacity: showNewPassword ? 1 : 0}}/>
                  </button>
                </div>
              </Grid>
            </Grid>
            <SpaceRow top="20"/>
            <Grid container alignItems="center">
              <Grid container item xs={6}>
                <p
                  className="main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart"
                  style={{color: confirmPasswordStateError ? 'red' : '#9b9b9b'}}>Nieuw wachtwoord
                  bevestigen</p>
              </Grid>
              <Grid container item xs={6}>
                <div className="flex-row width-auto horizontal-between">
                  <input
                    className="renew-password-input main-font-semi-bold font-size-14 font-color-black"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPasswordState}
                    onChange={handleConfirmPassword}/>
                  <button type="button" className="renew-password-eye-container"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    <img
                      src={require('../../../../../../assets/images/icon/see_darkbleu.svg')}
                      alt="eye" className="see_darkbleu"
                      className="renew-password-eye animatable-400 ease-in-out-quart"
                      style={{opacity: showConfirmPassword ? 0 : 1}}/>
                    <img
                      src={require('../../../../../../assets/images/icon/see_no_darkbleu.svg')}
                      alt="eye" className="see_darkbleu"
                      className="renew-password-eye animatable-400 ease-in-out-quart"
                      style={{opacity: showConfirmPassword ? 1 : 0}}/>
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
          <button
            type="button"
            disabled={loadState}
            onClick={handleButtonSubmit}
            className="renew-password-button center-content"
          >
            <img
              src={require('../../../../../../assets/images/iconround/check_white.svg')}
              alt="round check" className="renew-password-button-icon"/>
            <p
              className="main-font-semi-bold font-size-14 font-color-white">Bewaren</p>
          </button>
        </div>
      </Grid>
    </SlidingCard>
  );
}

RenewPassword.propTypes = {
  toggleVisibility: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

RenewPassword.defaultProps = {
  isOpen: false,
};
