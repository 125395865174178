import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { SpaceRow } from '../../../components/space/Space';
import { getUserProject, getAllBuyerProjects } from "../../../common/api/routes";
import { SQLDateToString } from "../../../common/utils/format";

import "../login.scss";

function ProjectButton(props) {
    const { projectData, onClick } = props;

    return(
        <Grid container>
            <button type = "button" className = "project-select-button center-content animatable-400 ease-in-out-quart" onClick = {onClick} >
                <p className = "main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart">{projectData?.project?.name || "null"} - {projectData?.created_at ? SQLDateToString(projectData.created_at) : "null"}</p>
            </button>
        </Grid>
    )
}

export default function ProjectSelect() {
    const [ projectData, setProjectData ] = useState([])

    const history = useHistory();
    const userData = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        axios.get(getUserProject(userData.id)).then(response => {
            setProjectData(response.data.data)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    function handleProjectClick(id) {
        localStorage.setItem('selected-project', id);
        history.push("dashboard/projects")
    }

    return(
        <Grid container className = "fade-in-translate">
            <SpaceRow top="35" />
            <Grid container alignItems = "center" >
                <img src = {require('../../../assets/images/icon/project_white.svg')} alt = "project" className = "project-select-icon margin-right-14" />
                <p className = "main-font-bold font-size-20 font-color-white">Kies uw project</p>
            </Grid>
            <SpaceRow top="37" />

            <Grid container>
                {projectData.map((project, index) => (
                    <>
                        <ProjectButton projectData = {project} onClick = {() => handleProjectClick(project.id) } />
                        {index !== (projectData.length - 1) && (
                            <SpaceRow top = '20' />
                        )}
                    </>
                ))}
            </Grid>
        </Grid>
    )
}

ProjectButton.propTypes = {
    projectData: PropTypes.object,
}

ProjectButton.defaultProps = {
    projectData: {
        title: '*ProjectName* - *SignatureDate* '
    },
}