import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ErrorBoundary } from 'react-error-boundary';

import DocumentInfo from '../../../../../../components/document-info/DocumentInfo';
import ErrorMessage from '../../../../../../components/error-message/ErrorMessage';

const Beheer = ({
  selectedCategory, data, projectData, triggerManualUpdate, loadedDocument, setLoadedDocument
}) => {
  const [documents, setDocuments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [documentsError, setDocumentsError] = useState('');

  useEffect(() => {
    if(data && data.length > 0) {
      setDocuments(data)
    }
  }, [data]);

  return (
    <Grid container>
      <Grid item xs={11} sm={10} md={9} lg={6}>
        <ErrorBoundary FallbackComponent={ErrorMessage}>
          <DocumentInfo
            documentsError={documentsError}
            isFetching={isFetching}
            documents={documents}
            projectData = {projectData}
            triggerManualUpdate = {triggerManualUpdate}
            loadedDocument = {loadedDocument} 
            setLoadedDocument = {setLoadedDocument}
          />
        </ErrorBoundary>
      </Grid>
    </Grid>
  );
};

Beheer.defaultProps = {};

Beheer.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
};

export default Beheer;
