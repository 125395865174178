import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import './core.scss'
import '../assets/styles/scss/fonts.css'
import '../assets/styles/scss/globals.css'

import ErrorMessage from '../components/error-message/ErrorMessage'

// page imports here
import Login from './auth/Login.view'
import ForgotPassword from './auth/forgot_password/forgot_password'
import ResetPassword from './auth/reset_password/reset_password'
import Dashboard from './dashboard/Dashboard.view'
import ChooseProject from './chooseProject/ChooseProject.view'
import CookiesPopUp from '../components/cookie-pop-up/cookies'

export default function Core() {
  const [renderState, setRenderState] = useState(false)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    // first we check if the user selected stay logged in checkbox
    let stayLoggedIn = localStorage.getItem('stayLoggedIn')
    const query = new URLSearchParams(location.search)
    console.log("core location",location)
    console.log("query",query)

    if (stayLoggedIn && ! query.get("token")) {
      stayLoggedIn = JSON.parse(stayLoggedIn)
      if (stayLoggedIn) {
        // we check again after json parse to see if the value is indeed true not false, the above check is to check if it is a non null value
        // next we verify the validity of the user token
        let userData = localStorage.getItem('user')
        if (userData) {
          userData = JSON.parse(userData)
          if (location.pathname === '/') {
            history.push('/dashboard/projects')
          }
          // api call to check token validity call here
        } else {
          history.push('/')
        }
      } else {
        history.push('/')
      }
    } else {
      if((location.pathname != "/reset_password" || location.pathname != "/forgot_password") && !query.get("token")){
        history.push('/')
      } else {
        console.log("reset_password page");
      }
    }
    setRenderState(true)
  }, [])

  return (
    <Switch>
      {/* we want don't want to render components until route has been decided by checking user token validity, since it will redirect to login otherwise */}
      {renderState && (
        <>
          <Route path="/dashboard">
            <ErrorBoundary FallbackComponent={ErrorMessage}>
              <Dashboard />
            </ErrorBoundary>
            <CookiesPopUp />
          </Route>

          <Route path="/projects">
            <ChooseProject />
            <CookiesPopUp />
          </Route>

          <Route path = "/forgot_password">
            <ForgotPassword />
          </Route>

          <Route path = "/reset_password">
            <ResetPassword />
          </Route>

          <Route exact path="/">
            <ErrorBoundary FallbackComponent={ErrorMessage}>
              <Login />
            </ErrorBoundary>
          </Route>
        </>
      )}
    </Switch>
  )
}
