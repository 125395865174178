import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { useRouteMatch, Link } from 'react-router-dom';

import './footer.scss';

export default function Footer() {
  const { url } = useRouteMatch();

  return (
    <Grid container className="fade-in-bottom">
      <Hidden smDown>
        <Grid
          container
          className="footer-container background-color-faded-faint-grey"
        >
          <div className="flex-row width-auto margin-left-110 vertical-center ">
            <div className="flex-row width-auto">
              <p
                className="font-size-12 vietnam-regular font-color-blue-grey margin-right-60"
              >
                2021 Gaelens bouwprojecten
              </p>
              <Link to={`${url}/privacy`}>
                <p
                  className="font-size-12 vietnam-regular font-color-blue-grey margin-right-60"
                >
                  Privacy policy
                </p>
              </Link>
              <Link to={`${url}/privacy`}>
                <p
                  className="font-size-12 vietnam-regular font-color-blue-grey margin-right-60"
                >
                  Cookies
                </p>
              </Link>
            </div>
            <div
              className="width-auto flex-row vertical-center horizontal-end "
            >
              <a href = "https://agifly.be/" target="_blank">
                <img
                  alt="logo"
                  src={require('../../../../assets/images/pictures/logo_agifly.svg')}
                  className="footer-logo margin-right-120"
                />
              </a>
            </div>
          </div>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          className="footer-container background-color-faded-faint-grey"
          direction = "column"
        >
          <img
            alt="logo"
            src={require('../../../../assets/images/pictures/logo_color.svg')}
            className="header-logo"
          />
          <Link to={`${url}/privacy`}>
            <p
              className="font-size-12 vietnam-regular font-color-blue-grey margin-top-10 margin-right-140 width-auto"
            >
              Cookies
            </p>
          </Link>
          <Link to={`${url}/privacy`}>
            <p
              className="font-size-12 vietnam-regular font-color-blue-grey margin-top-20 margin-right-50 width-auto"
            >
              Privacy policy
            </p>
          </Link>
          <div
            className="flex-row width-auto vertical-center horizontal-between margin-top-20"
          >
            <p
              className="font-size-12 vietnam-regular font-color-blue-grey "
            >
              2021 Gaelens bouwprojecten
            </p>
            <a href = "https://agifly.be/" target="_blank">
              <img
                alt="logo"
                src={require('../../../../assets/images/pictures/logo_agifly.svg')}
                className="footer-logo cursor-pointer"
              />
            </a>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
}
