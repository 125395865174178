import React, { useState } from 'react';

import './switch.scss';

export default function Switch() {
  const [switchState, setSwitchState] = useState(false);

  return (
    <div className="switch-container animatable-400 ease-in-out-quart" onClick={() => setSwitchState(!switchState)} style={{ background: switchState ? 'white' : 'rgba(45, 160, 179, 0.13)' }}>
      <div className="switch animatable-400 ease-in-out-quart" style={{ left: switchState ? '19px' : '-1px', background: switchState ? 'rgb(200, 200, 200)' : 'rgb(45, 160, 179)', borderColor: switchState ? 'rgb(200, 200, 200)' : 'rgb(45, 160, 179)' }} />
    </div>
  );
}
