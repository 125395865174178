export const defaultUrl = "https://backend.staging.bim.flykube.agifly.cloud/api/v1/";
export const defaultBackend = "https://backend.staging.bim.flykube.agifly.cloud";

// const defaultUrl = "http://localhost:3000/api/v1/";

// auth api routes here
export const login = defaultUrl + "auth/sign_in";

export const changePassword = defaultUrl + "auth/change_password";

export const loginWithToken = token => {
    return defaultUrl + "user/signIn/" + token;
}

export const forgotPassword = defaultUrl + "auth/forgot_password";

export const resetPassword = defaultUrl + "auth/reset_password";

export const newPassword = defaultUrl + "user/access/signIn";

// user api routes here
export const userGet = (userId) => {
    return defaultUrl + "user/show/" + userId.toString();
}

export const userUpdate = (userId) => {
    return defaultUrl + "user/" + userId.toString();
}

// buyer project api routes here
export const getUserUnit = (userId) => {
    return defaultUrl + "buyer/project/" + userId.toString();
}

export const getUserProject = (userId) => {
    return defaultUrl + "buyer/project/list/" + userId.toString();
}

export const getProjectById = (projectId) => {
    return defaultUrl + "buyer/project/show/" + projectId.toString();
}

export const getAllBuyerProjects = defaultUrl + "buyer/project";

// message api routes here
export const getDocumentMessages = (projectId) => {
    return defaultUrl + "chat/project/" + projectId;
}

export const getChatsbyProjectUnitUser =  (projectId, child_buyer) => {
    return defaultUrl + "chat/old/" + projectId + "/" +child_buyer + "/1";
}

export const getSingleChat = (chatId) => {
    return defaultUrl + "chat/show/" + chatId;
}

export const getGeneralChatFromBuyer = buyerId => {
    return defaultUrl + "chat/buyer/" + buyerId;
}

export const getChatFromDocument = (documentId, buyerId) => {
    return defaultUrl + "chat/document/" + documentId + "/" +buyerId;
}

export const getChatById = (id, child_buyer) => {
    return defaultUrl + "chat/buyer/" + id + "/" + child_buyer;
}

export const sendMessage = defaultUrl + "chat/message/send";

export const createMessage = defaultUrl + "chat/";

// document api routes here
export const getDocumentsByUnits = (categoryId, userId, projectId, unitList) => {
    return `${defaultUrl}document/category/${categoryId}/${userId}/${projectId}/sold?units=${unitList}`;
}

export const getDocumentsByProject = (projectId, search) => {
    return defaultUrl + "document/project/" + projectId + "?search=" + search;
}

export const getDocumentById = documentid => {
    return defaultUrl + "document/show/" + documentid;
}

// notification api routes here
export const getAllNotificationByUser = (userId, projectId, buyerProjectId) => {
    return defaultUrl + "notification/user/" + userId + "/" + projectId + "/" + buyerProjectId;
}

export const createNotification = defaultUrl + "notification";

export const readNotification = (notifId) => {
    return defaultUrl + "notification/" + notifId;
}

export const readChatNotification = (chatId, userId) => {
    return defaultUrl + "notification/chat/read/" + chatId + "/" + userId;
}

// user title api routes here
export const getUserTitles = defaultUrl + "user/title";
