import React, { useState } from 'react';
import { Grid, Hidden } from '@material-ui/core';

import { SpaceRow } from '../../../../../components/space/Space';
import Switch from '../../../../../components/switch/switch';
import Footer from '../../footer/Footer';

import './privacy.scss';

export default function Privacy() {
  return (
    <div className="privacy-container">
      <Grid container justify="center">
        <Grid container xs={11}>
          <Hidden xsDown>
            <SpaceRow top="24" />
          </Hidden>
          <Hidden smUp>
            <Grid container item xs={12}>
              <Grid container>
                <p className="main-font-bold font-size-20 font-color-black">Manage Consent Preferences</p>
              </Grid>
              <SpaceRow top="15" />
              <Grid container>
                <div className="privacy-box">
                  <div className="privacy-box-item">
                    <div className="privacy-box-item-switch">
                      <Switch />
                    </div>
                    <div className="flex-column width-auto">
                      <p className="main-font-semi-bold font-size-16 font-color-black">Strikt noodzakelijke cookies</p>
                      <SpaceRow top="5" />
                      <p className="main-font-regular font-size-14 font-color-black">Deze cookies zijn nodig om de website te laten functioneren en kunnen niet worden uitgeschakeld in onze systemen. Ze worden meestal alleen ingesteld naar aanleiding van acties van u die neerkomen op een verzoek om diensten, zoals het instellen van uw privacy voorkeuren, aanmelden of het invullen van formulieren. U kunt uw browser instellen om deze cookies te blokkeren of u te waarschuwen voor deze cookies, maar sommige delen van de site zullen dan niet werken. Deze cookies slaan geen persoonlijk identificeerbare informatie op.</p>
                    </div>
                  </div>
                  {/* <SpaceRow top="50" />
                  <div className="privacy-box-item">
                    <div className="privacy-box-item-switch">
                      <Switch />
                    </div>
                    <div className="flex-column width-auto">
                      <p className="main-font-semi-bold font-size-16 font-color-black">Analytics Cookies</p>
                      <SpaceRow top="5" />
                      <p className="main-font-regular font-size-14 font-color-black">These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.</p>
                    </div>
                  </div>
                  <SpaceRow top="50" />
                  <div className="privacy-box-item">
                    <div className="privacy-box-item-switch">
                      <Switch />
                    </div>
                    <div className="flex-column width-auto">
                      <p className="main-font-semi-bold font-size-16 font-color-black">Targeting Cookies</p>
                      <SpaceRow top="5" />
                      <p className="main-font-regular font-size-14 font-color-black">These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.    They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</p>
                    </div>
                  </div>
                  <SpaceRow top="25" /> */}
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Grid container item xs={12} sm={6}>
            <Grid container>
              <p className="main-font-bold font-size-20 font-color-black">Privacy Preference Center</p>
            </Grid>
            <SpaceRow top="20" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Verwerkingsverantwoordelijke</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Gaelens Bouwprojecten</p>
              <p className="main-font-regular font-size-14 font-color-black">info@gaelensbouwprojecten.be</p>
              <p className="main-font-regular font-size-14 font-color-black">Kortrijksesteenweg 1008 Bus 001</p>
              <p className="main-font-regular font-size-14 font-color-black">B-9000 Gent</p>
            </Grid>
            <SpaceRow top="20" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">BTW: BE 0842.289.206</p>
              <p className="main-font-regular font-size-14 font-color-black">RPR: Gent, afdeling Gent</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Algemene Verordening Gegevensbescherming</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Gaelens Bouwprojecten stelt uw interesse in haar activiteiten en uw bezoek aan haar website op prijs. Wij vinden het belangrijk dat u zich niet onzeker voelt bij het bezoeken van onze website en willen ervoor zorgen dat het duidelijk is wat er gebeurt met de persoonlijke gegevens die u aan ons ter beschikking stelt.</p>
              <SpaceRow top="20" />
              <p className="main-font-regular font-size-14 font-color-black">Uw persoonlijke gegevens, inclusief uw e-mail adres, zullen als volgt worden gebruikt:</p>
              <SpaceRow top="20" />
              <p className="main-font-regular font-size-14 font-color-black">informatie die u ons geeft, met als doel informatie van ons te verkrijgen, slaan we op om uw informatieaanvraag te kunnen uitvoeren. Deze informatie wordt niet langer bewaard dan nodig.</p>
              <p className="main-font-regular font-size-14 font-color-black">informatie die u ons geeft in het kader van een sollicitatie, slaan we op en behandelen we met dat doel voor ogen met uiterste zorgvuldigheid. Deze informatie wordt niet langer bewaard dan nodig.</p>
              <p className="main-font-regular font-size-14 font-color-black">de opgeslagen informatie zullen we ook aanwenden om via bezoekersanalyses onze website verder te optimaliseren</p>
              <p className="main-font-regular font-size-14 font-color-black">Slechts wanneer u Gaelens Bouwprojecten op uitdrukkelijke wijze toestemming daartoe verleent, worden uw</p>
              <p className="main-font-regular font-size-14 font-color-black">persoonsgegevens verwerkt voor direct marketingdoeleinden. U kan zich te allen tijde kosteloos verzetten tegen het gebruik van uw persoonsgegevens voor direct marketingdoeleinden. U richt daartoe een eenvoudig verzoek tot bovenstaande contactpersoon.</p>
              <p className="main-font-regular font-size-14 font-color-black">we zullen uw persoonlijke informatie niet ter beschikking stellen van of verkopen aan derden, die geen onderdeel of directe partner van Gaelens Bouwprojecten zijn.</p>
              <p className="main-font-regular font-size-14 font-color-black">Onze website bevat links naar niet-Gaelens Bouwprojecten websites. Gaelens Bouwprojecten is niet verantwoordelijk voor de manier waarop in deze websites met de persoonlijke gegevens wordt omgegaan.</p>
              <SpaceRow top="20" />
              <p className="main-font-regular font-size-14 font-color-black">De Europese Algemene Verordening Gegevensbescherming van 27 april 2016 (hierna kortweg ‘AVG’) legt de natuurlijke persoon of de rechtspersoon die het doel van en de middelen voor de verwerking van persoonsgegevens vaststelt (de verwerkingsverantwoordelijke), de verplichting op de natuurlijke personen wiens persoonsgegevens worden verwerkt (de betrokkenen), te informeren.</p>
            </Grid>
            <SpaceRow top="100" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Uw rechten</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Als bezoeker heeft u recht op inzage en toegang, recht op verbetering, wissing en beperking, recht van bezwaar, recht op het intrekken van toestemming, recht op overdraagbaarheid. Neem in dat geval contact op via bovenstaande contactgegevens.</p>
              <SpaceRow top="20" />
              <p className="main-font-regular font-size-14 font-color-black">De verwerking van persoonsgegevens door Gaelens Bouwprojecten gebeurt niet op basis van geautomatiseerde beslissingen, met andere woorden niet zonder enige menselijke tussenkomst.</p>
              <SpaceRow top="20" />
              <p className="main-font-regular font-size-14 font-color-black">Gaelens Bouwprojecten doet niet aan profiling op basis van de beschikbare persoonsgegevens.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Cookiebeleid</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Om onze websites beter af te kunnen stemmen op uw behoeften en voorkeuren, maakt Gaelens Bouwprojecten gebruik van cookies. U heeft de mogelijkheid om de cookies te weigeren. In dat geval maken we u er attent op dat bepaalde onderdelen van onze websites niet naar behoren kunnen functioneren. Het kan ook zijn dat u geen toegang meer zal hebben tot bepaalde diensten die wij op onze website aanbieden.</p>
              <p className="main-font-regular font-size-14 font-color-black">Hieronder geven wij u graag meer informatie over cookies aan de hand van een eenvoudige Q&A structuur.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Wat is een cookie?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Een cookie is een tekstbestand dat in de webbrowser van uw computer of uw mobiel toestel - zoals uw Smartphone, iPhone, iPad en dergelijke - nagelaten wordt door de server van de website die u op dat moment raadpleegt. Een cookie bevat een code die alleen gelezen kan worden door de server die het er geplaatst heeft. De server heeft dus geen toegang tot andere informatie die zich bevindt op uw computer of uw mobiel toestel.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Hoe is een cookie bestand opgebouwd?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Een cookiebestand bestaat uit:</p>
              <SpaceRow top="20" />
              <p className="main-font-regular font-size-14 font-color-black">- de naam van de server die het cookie bestand geplaatst heeft</p>
              <p className="main-font-regular font-size-14 font-color-black">- er wordt een vervaldatum meegegeven</p>
              <p className="main-font-regular font-size-14 font-color-black">- een unieke cijfercode</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Wat is het nut van een cookie?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Aan de hand van cookies zorgen we ervoor dat uw surfervaring als bezoeker op èèn van onze websites zo optimaal mogelijk ervaren wordt. Dit onder andere om u sneller en makkelijker te laten navigeren tussen de verschillende onderdelen van de website of door het aanpassen van de inhoud van onze site aan uw persoonlijke voorkeuren bvb taalvoorkeur.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Waar kan ik deze cookies terugvinden?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Cookies worden in een afzonderlijke folder van uw browser op uw computer of uw mobiel toestel geplaatst.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Wat is een Session cookie'?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Deze cookie heeft als functie om uw browser te herkennen gedurende het bezoek aan onze websites.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Wat is een 'Permanente cookie'?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Deze cookie heeft als functie om uw browser te herkennen met het oog op herhaalde bezoeken aan onze websites.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Wat zijn 'Functionele cookies'?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Zoals het woord zelf suggereert, zorgen deze cookies voor het optimaal functioneren van onze websites zodat u een zo aangenaam en persoonlijk mogelijk bezoek aan onze websites ervaart. Gaelens Bouwprojecten maakt gebruik van Functionele cookies' voor het herinneren' van uw persoonlijke voorkeuren en voor het vermijden van eenzelfde communicatie bij herhaalde bezoeken bijvoorbeeld bij het deelnemen aan een actie.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Wat zijn 'Performantie cookies'?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Gaelens Bouwprojecten maakt gebruik van Performantie cookies' om na te gaan hoe u onze websites gebruikt met het oog op het aanpassen en verbeteren van de inhoud en het gebruiksgemak van onze websites. Zo worden bijvoorbeeld het aantal bezoekers per pagina bijgehouden of de meest voorkomende taal waarin de website bezocht wordt.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Wat zijn 'Third Party cookies'?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Dat zijn cookies die weggeschreven worden vanuit advertenties die door derden op de websites van Gaelens Bouwprojecten vermeld worden. Dit met als doel om de advertenties en/of de inhoud van de advertenties aan uw persoonlijke voorkeuren aan te kunnen passen. U krijgt deze cookies uitsluitend te zien indien u deze niet uitschakelde. Bij het gebruik van third partie cookies beschikt noch Gaelens Bouwprojecten, beschikken noch de adverteerders (derden) over uw persoonlijke gegevens.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Wat is 'Embedded content'?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Gaelens Bouwprojecten kan op haar websites doorverwijzen naar andere sites via een aanklikbare link. Zo komt u op een website van een andere organisatie terecht. Het kan zijn dat de website naar waar verwezen wordt ook gebruik maakt van cookies. Gaelens Bouwprojecten adviseert u om op dat moment de gebruiksvoorwaarden van de betreffende organisatie te consulteren, vermeld op hun eigen website, om u te informeren omtrent hun privacy en cookie beleid.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Hoe kan ik de cookies beheren?</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Het beheren van cookies heeft u zelf in de hand. U kan de instellingen van uw browser aanpassen naar uw persoonlijke voorkeuren. U kan steeds zowel reeds geïnstalleerde cookies verwijderen als nieuwe cookies weigeren door uw browserinstellingen aan te passen.</p>
            </Grid>
            <SpaceRow top="50" />
            <Grid container>
              <p className="main-font-bold font-size-16 font-color-black">Google Analytics cookies</p>
            </Grid>
            <SpaceRow top="5" />
            <Grid container direction="column">
              <p className="main-font-regular font-size-14 font-color-black">Deze website maakt gebruik van Google Analytics, één van de meest gekende alsook vertrouwde oplossing voor analytische gegevensverzameling op het internet. Deze helpt ons te begrijpen hoe u gebruik maakt van de website op een manier waarop bij bijgevolg verbeteringen kunnen aanbrengen. Deze cookies kunnen bijvoorbeeld bijhouden hoe lang u zich op onze website bevindt alsook de pagina's die u bezoekt.</p>
              <SpaceRow top="20" />
              <p className="main-font-regular font-size-14 font-color-black">Voor meer informatie over Google Analytics kan u de officiële pagina bezoeken.</p>
              <a href="https://policies.google.com/technologies/cookies" target="_blank" className="main-font-regular font-size-14 font-color-black text-underline" rel="noreferrer">https://policies.google.com/technologies/cookies</a>
            </Grid>
            <SpaceRow top="50" />
          </Grid>
          <Hidden xsDown>
            <Grid container item sm={1} />
          </Hidden>
          <Hidden xsDown>
            <Grid container item sm={5} className="height-disable relative">
              <Grid container item sm={5} className="position-fixed">
                <Grid container>
                  <p className="main-font-bold font-size-20 font-color-black">Manage Consent Preferences</p>
                </Grid>
                <SpaceRow top="15" />
                <Grid container>
                  <div className="privacy-box">
                    <div className="privacy-box-item">
                      <div className="privacy-box-item-switch">
                        <Switch />
                      </div>
                      <div className="flex-column width-auto">
                        <p className="main-font-semi-bold font-size-16 font-color-black">Strictly Necessary Cookies</p>
                        <SpaceRow top="5" />
                        <p className="main-font-regular font-size-14 font-color-black">These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.    You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>
                      </div>
                    </div>
                    {/* <SpaceRow top="50" />
                    <div className="privacy-box-item">
                      <div className="privacy-box-item-switch">
                        <Switch />
                      </div>
                      <div className="flex-column width-auto">
                        <p className="main-font-semi-bold font-size-16 font-color-black">Analytics Cookies</p>
                        <SpaceRow top="5" />
                        <p className="main-font-regular font-size-14 font-color-black">These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.</p>
                      </div>
                    </div>
                    <SpaceRow top="50" />
                    <div className="privacy-box-item">
                      <div className="privacy-box-item-switch">
                        <Switch />
                      </div>
                      <div className="flex-column width-auto">
                        <p className="main-font-semi-bold font-size-16 font-color-black">Targeting Cookies</p>
                        <SpaceRow top="5" />
                        <p className="main-font-regular font-size-14 font-color-black">These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.    They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</p>
                      </div>
                    </div>
                    <SpaceRow top="25" /> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Hidden smUp>
        <Footer />
      </Hidden>
    </div>
  );
}
