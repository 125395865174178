import React, { useEffect } from 'react';
import { Box, Avatar, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { createNotification, defaultBackend, readNotification } from '../../../common/api/routes';

const DocHeader = ({ documentType, documentName, doc, buyerId }) => {
  const useStyles = makeStyles(() => ({
    avatar: {
      width: '33px',
      height: '33px',
      marginTop: '10px',
      margin: 'inherit',
      border: '0.2px solid #ddd',
    },
  }));
  const userData = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if(doc?.new?.id) {
      // we find the notification relevant to our user
      axios.put(readNotification(doc.new.id)).catch(error => console.log(error))
    }
  }, [doc])

  const downloadDoc = (d) => {
    axios.get(defaultBackend + d.path, {
      responseType: 'blob'
    }).then(response => {
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = d.filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      createNewNotification(false)
    }).catch(error => console.log(error))
  };

  const handleClick = (path) => {
    window.open(defaultBackend + path);
    createNewNotification(true)
  };

  const classes = useStyles();
  // eslint-disable-next-line no-shadow
  const getDocumentImage = (documentType) => {
    if (documentType) {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`../../../assets/images/document/${documentType}.svg`);
    }
    // eslint-disable-next-line import/no-dynamic-require
    return require('../../../assets/images/document/other.svg');
  };

  function createNewNotification(notifType) {
    axios.post(createNotification, {
      user_id: userData.id,
      project_id: doc.project.id,
      document_id: doc.id,
      buyer_project_id: buyerId,
      title: notifType ? "Document View" : 'Document Download',
      description: notifType ? `${userData.firstName} ${userData.lastName} bekeek het document: ${doc.filename} uit categorie: ${doc.category.title}` : `${userData.firstName} ${userData.lastName} downloadde het document: ${doc.filename} uit categorie: ${doc.category.title}`,
      type: notifType ? 4 : 2,
      notification_type: 0,
    }).then(response => console.log(response.data)).catch(error => console.log(error))
  }

  const path = document.path;

  return (
    <Grid container>
      <Box mx="auto" className="centerAlign flex-column">
        <Avatar
          variant="rounded"
          alt="extension"
          src={getDocumentImage(documentType)}
          className={classes.avatar}
        />
        <h2
          className="title main-font main-font-bold"
        >
          {documentName}
        </h2>
      </Box>

      <Grid
        container
        className="gridmargin"
        display="flex"
        flex="row"
      >
        {/*  */}
        <Grid item xs={6} className="centerAlign">
          <a
          // href={document.path}
            onClick={() => { downloadDoc(doc); }}
            // download={document.filename+'.'+document.type}
            type="button"
            className="btn-darkBleu btn-content main-font-semi-bold margin-right-18"
            style = {{height: '20px'}}
          >
            <img
              alt="download"
              src={require('../../../assets/images/icon/download_white.svg')}
              className="header-icons margin-right-18"
            />
            Downloaden
          </a>
        </Grid>
        <Grid item xs={6} className="centerAlign">
          <a
            // href={path}
            onClick={(document) => { handleClick(doc.path); }}
            target="_blank"
            className="btn-darkBleu btn-content main-font-semi-bold"
            style = {{height: '20px'}}
          >
            <img
              alt="preview"
              src={require('../../../assets/images/icon/see_white.svg')}
              className="header-icons margin-right-18"
            />
            Preview
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};
DocHeader.defaultProps = {
  documentType: '',
  documentName: '',
  doc: {}
};
DocHeader.propTypes = {
  doc: PropTypes.object,
  documentName: PropTypes.string,
  documentType: PropTypes.string
};

export default DocHeader;
