import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';

const getFileExtension = file => {
  const { filename } = file;
  let extension = filename.split(".");
  extension = extension[extension.length - 1];
  return extension || "";
}

const DocInfo = ({ doc }) => {
  const docInfoArr = [
    {
      label: 'Uploaddatum',
      value:moment(doc.created_at)
        .format('DD/MM/YY')
    },
    {
      label: 'Laatst bekeken',
      value: doc?.notifications?.created_at ? moment(doc?.notifications?.created_at)
        .format('DD/MM/YY') : "-",
    },
    {
      label: 'Bestandsgrootte',
      value: `${doc.size} mb`,
    },
    {
      label: 'Bestandstype ',
      value: getFileExtension(doc),
    },
  ];

  const dom = docInfoArr.map((docinfo, i) => (
    <Grid
      container
      key={i}
      className="docinfo-row"
    >
      <Grid item xs={6}>
        <p className="text-gray font-size-14">{docinfo.label}</p>
      </Grid>
      <Grid item xs={6}>
        <strong className="font-size-14 ">{docinfo.value}</strong>
      </Grid>
    </Grid>
  ));
  return dom;
};
DocInfo.defaultProps = {
  document: {},
};
DocInfo.propTypes = {
  document: PropTypes.object,
};

export default DocInfo;
