import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Picker from 'emoji-picker-react';
import { delay, trim } from "lodash";
import axios from "axios";

import { SpaceRow } from '../../../../../../../components/space/Space';
import { SQLDateTimeToString } from "../../../../../../../common/utils/format";
import { sendMessage, createMessage, getSingleChat, getChatFromDocument, readChatNotification } from "../../../../../../../common/api/routes";

import './message_panel.scss';

function Message(props) {
  const { type, data } = props;
  // type controls the appearance of the message, there are currently two versions, grey and blue
  // if type value is 1 then it is grey and if it is 2, then it is blue

  return (
    <Grid container justify={type === 1 ? 'flex-start' : 'flex-end'} className = "fade-in-translate">
      <div className={type === 1 ? 'message-panel-box-grey' : 'message-panel-box-blue'}>
        <p className="main-font-regular font-size-14 font-color-white">{data.message}</p>
      </div>
      <SpaceRow top="3" />
      <Grid container justify={type === 1 ? 'flex-start' : 'flex-end'} className={type === 1 ? 'margin-left-12' : 'margin-right-12'}>
        <p className="main-font-regular font-size-12 font-color-blue-grey">{data?.user?.firstName} {data?.user?.lastName}</p>
        <p className="main-font-regular font-size-12 font-color-blue-grey">&nbsp;-&nbsp;</p>
        <p className="main-font-regular font-size-12 font-color-blue-grey">{SQLDateTimeToString(data?.created_at)}</p>
      </Grid>
    </Grid>
  );
}

export default function MessagePanel(props) {
  const [message, setMessage] = useState('');
  const [emojiMenuState, setEmojiMenuState] = useState(false);
  const [conversation, setConversation ] = useState({});
  const [ newMessageState, setNewMessageState ] = useState(false)
  const [ manualUpdate, setManualUpdate ] = useState(false)
  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);

  const userData = JSON.parse(localStorage.getItem('user'));
  const selectedBuyerProject = JSON.parse(localStorage.getItem('selected-project'));
  const messagesListRef = useRef(0)
  const { data, documentId, hasAccess, buyerId } = props;

  useEffect(() => {
    if(data || conversation?.id) {
      axios.get(getSingleChat(data?.id || conversation?.id)).then(response => {
        setConversation(response.data.data);
      }).catch(error => console.log(error))

      axios.put(readChatNotification(data?.id || conversation?.id, userData.id)).then(response => {
        console.log(response.data)
      }).catch(error => console.log(error))
    }
  }, [data, manualUpdate]);

  useEffect(() => {
    // real time functionality using a delay based recursion
    delay(() => {
      setManualUpdate(!manualUpdate)
    }, 5000)
  }, [manualUpdate])

  useEffect(() => {
    if(documentId) {
      axios.get(getChatFromDocument(documentId, buyerId)).then(response => {
        if(response?.data?.data?.length > 0) {
          setConversation(response.data.data[0])
        }
        else {
          setNewMessageState(true)
        }
      }).catch(error => console.log(error))
    }
  }, [documentId])

  useEffect(() => {
    if(conversation || data) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight
    }
  }, [conversation, data])

  const handleEmojiClick = (event, emojiObject) => {
    setMessage(message + emojiObject.emoji);
  };

  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleMessageSubmit = () => {
    if (trim(message) && !isSubmittingMessage) {
      setIsSubmittingMessage(true);
      axios.post(sendMessage, {
        chat_id: conversation.id,
        user_id: userData.id,
        message: message,
      }).then(response => {
        let conversationCopy = { ...conversation };
        let newMessage = response.data.data
        conversationCopy.messages.push(newMessage);
        newMessage.userId = newMessage.user_id;
        newMessage.createdAt = newMessage.created_at;
        setConversation(conversationCopy);
        setMessage("");
        messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
      }).catch(error => console.log(error))
        .finally(() => setIsSubmittingMessage(false));
    }
  }

  const handleNewMessage = () => {
    if (trim(message) && !isSubmittingMessage) {
      setIsSubmittingMessage(true);
      axios.post(createMessage, {
        document_id: documentId,
        buyer_project_id: selectedBuyerProject,
        messages: [
          {
            user_id: userData.id,
            message,
          }
        ]
      }).then(response => {
        setNewMessageState(false)
        setMessage("");
        setConversation(response.data.data);
      }).catch(error => console.log(error))
        .finally(() => setIsSubmittingMessage(false));
    }
  }

  return (
    <div className="message-panel-container">
      <Grid container justify="center" className="height-auto">
        <SpaceRow top="24" />
        <Grid container>
          <div className="message-panel-active-document center-content">
            <p className="main-font-regular font-size-14 font-color-blue-grey">Document {conversation?.document?.filename || ""}</p>
          </div>
        </Grid>
        <SpaceRow top="35" />
        <div className="message-panel-box-container" ref = {messagesListRef}>
          <Grid container>
            {conversation && conversation.messages && conversation.messages.length > 0 && conversation.messages.map(messageItem => (
              <>
                <Message type = {userData?.id == messageItem?.user?.id ? 2 : 1} data = {messageItem} />
                <SpaceRow top="23" />
              </>
            ))}
          </Grid>
        </div>
        {hasAccess && (
          <div className="message-panel-input-container horizontal-between">
            <div className="message-panel-input">
              <div className="message-panel-input-emoji center-content cursor-pointer relative" onClick={() => setEmojiMenuState(!emojiMenuState)}>
                <p className="main-font-regular font-size-12">😃</p>
                <div className="message-panel-input-emoji-container fade-in-translate" style={{ display: emojiMenuState ? 'block' : 'none' }}>
                  <Picker onEmojiClick={handleEmojiClick} />
                </div>
              </div>
              <input className="message-panel-input-field main-font-regular font-size-14 font-color-black" placeholder="Typ bericht ..." value={message} onChange={handleMessage} onKeyDown = {event => { if(event.key === "Enter") newMessageState ? handleNewMessage() : handleMessageSubmit();}} />
            </div>
            <button type="button" className="message-panel-input-button center-content" onClick = {newMessageState ? handleNewMessage : handleMessageSubmit}>
              <img src={require('../../../../../../../assets/images/icon/send_white.svg')} alt="send" className="message-panel-input-button-icon" />
            </button>
          </div>
        )}
      </Grid>
    </div>
  );
}

Message.defaultProps = {
  type: 1,
  data: {
    message: 'Ik heb juist de plannen van de keuken gezien! 😃 Is het mogelijk om een A++ Frigo hebben? Afhankelijk van de prijzen?',
    firstname: 'Jane',
    lastname: 'Doe',
    date: '12/06/20',
    time: '13:59',
  },
};

Message.propTypes = {
  type: PropTypes.number,
  data: PropTypes.object,
};
