import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';

import './document-info.scss';
import TooltipHandler from '../Tooltip';
import Spinner from '../spinner/Spinner';
import DocSlideCard from '../shared/sliding_card/DocSlideCard';

// eslint-disable-next-line react/prop-types
const DocumentInfo = ({
  documents,
  showStatus,
  isFetching,
  showConcerned,
  setIsFetching,
  projectData,
  triggerManualUpdate,
  loadedDocument,
  setLoadedDocument
}) => {
  const [isSelected, setSelectedState] = useState(0);
  const [documentData, setdocumentDataState] = useState('');
  const [toggle, setToggle] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [hasAccess, setHasAccess] = useState(false)
  const [buyerId, setBuyerId] = useState(null)

  const userData = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    setBuyerId(projectData.id)
    if(projectData?.user) {
      // now we find the user in the buyer project object
      if(userData.id == projectData?.user?.id) {
        // this means that this is the main user and we select root access value
        if(projectData?.access == 0) {
          setHasAccess(true)
        }
      }
      else {
        const currentUser = projectData?.childs?.find(user => user.user.id == userData.id)
        if(currentUser?.access == 0) {
          setHasAccess(true)
        }
      }
    }
  }, [projectData])

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [screenWidth]);

  useEffect(() => {
    if(loadedDocument?.id && !loadedDocument?.viewed) {
      setLoadedDocument({...loadedDocument, viewed: true});
      setdocumentDataState(loadedDocument);
      setToggle(true);
    }
  }, [loadedDocument])

  const updateSize = () => {
    setScreenWidth(window.innerWidth);
  };

  const ShowSligingCard = () => {
    const state = !toggle;
    setToggle(state);
    return state;
  };

  const getDocumentImage = (documentType) => {
    if (documentType) {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`../../assets/images/document/${documentType}.svg`);
    }
    return require('../../assets/images/document/other.svg');
  };

  const calculateStatusImage = (status) => {
    let image = '';
    if (status === 0) {
      image = 'check_grey';
    } else if (status === 1) {
      image = 'check_green';
    } else if (status === 2) {
      image = 'chat_green';
    } else if (status === 3) {
      image = 'cross_red';
    }
    return image;
  };

  function grabFileExtension(file) {
    if(file) {
      const fileString = file.split(".");
      const extension = fileString[fileString.length - 1];
      if(extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "bmp") {
        return "img";
      }
      else if(extension === "pdf") {
        return "pdf";
      }
      else if(extension === "doc" || extension === "docx") {
        return "doc";
      }
      else if(extension === "xls" || extension === "xlsx") {
        return "xls";
      }
      else {
        return "other";
      }
    }
    else {
      return null;
    }
  }

  const {
    alignCenter,
    alignCenterItem,
  } = DocumentInfo.styles;

  return (
    <Grid container>
      {isFetching ? (
        <div className="text-center padding-top-20">
          <Spinner text="Fetching Documents..." />
        </div>
      ) : (
        <>
          {documents && documents.length ? (
            <>
              {screenWidth < 959 ? (
                <>
                  <Grid item xs={2}>
                    <p className="font-size-12 vietnam-regular font-color-grey">
                      Type
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <p
                      className="font-size-12 vietnam-regular font-color-grey "
                    >
                      Naam
                    </p>
                  </Grid>
                  <Grid item xs={2} style={alignCenterItem}>
                    {showStatus && (
                      <p
                        className="font-size-12 vietnam-regular font-color-grey"
                      >
                        Status
                      </p>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={4} />
                  {showConcerned && (
                    <Grid
                      item
                      xs={2}
                      style={alignCenterItem}
                      className="hide-small"
                    >
                      <p
                        className="font-size-12 vietnam-regular font-color-grey"
                      >
                        Onderwerp
                      </p>
                    </Grid>
                  )}
                  <Grid item xs={2} style={alignCenterItem}>
                    <p
                      className="font-size-12 vietnam-regular font-color-grey "
                    >
                      Uploaddatum
                    </p>
                  </Grid>
                  <Grid item xs={2} style={alignCenterItem}>
                    <p
                      className="font-size-12 vietnam-regular font-color-grey "
                    >
                      Laatst bekeken
                    </p>
                  </Grid>
                  <Grid item xs={2} style={alignCenterItem}>
                    {showStatus && (
                      <p
                        className="font-size-12 vietnam-regular font-color-grey"
                      >
                        Status
                      </p>
                    )}
                  </Grid>
                </>
              )}

              {documents && documents.map((document, index) => (
                <Grid
                  container
                  key={document.title}
                  className="document-info-container cursor-pointer relative"
                  onClick={() => {
                    setSelectedState(index + 1);
                    ShowSligingCard();
                    setdocumentDataState(document);
                  }}
                  style={{
                    background: isSelected === index + 1 && 'rgba(69, 84, 93, 0.0767045)',
                    marginLeft: screenWidth < 959 ? -20 : -40,
                    paddingLeft: screenWidth < 959 ? 20 : 40,
                    width: '110%',
                  }}
                >
                  {screenWidth < 959 ? (
                    <>
                      <Grid
                        xs={2}
                        item
                        style={alignCenter}
                        className="document-type"
                      >
                        {document?.new?.read_at && (
                          <div className="category-unread-dot" />
                        )}
                        <img
                          alt="extension"
                          src={getDocumentImage(grabFileExtension(document.filename))}
                          className="document-info-extension-image"
                        />
                      </Grid>
                      <Grid item xs={8} style={alignCenter}>
                        <p
                          className="font-size-14 font-color-blue-grey vietnam-bold"
                        >
                          {document?.filename || ""}
                        </p>
                        {document?.category.parent_id == 4 && <p className="font-size-12 font-color-grey vietnam-bold margin-left-10 margin-top-4">{document?.category?.title || ""}</p>}
                        {document?.category_id == 4 && <p className="font-size-12 font-color-grey vietnam-bold margin-left-10 margin-top-4">Andere</p>}
                      </Grid>
                      <Grid item xs={2} style={alignCenterItem}>
                        {showStatus && (
                          document.statusText ? (
                            <TooltipHandler
                              overlay={document.statusText}
                              trigger={['hover']}
                              placement="right"
                            >
                              <img
                                height={24}
                                alt="status"
                                src={getDocumentImage(calculateStatusImage(document.status))}
                              />
                            </TooltipHandler>
                          ) : (
                            <img
                              height={24}
                              alt="status"
                              src={getDocumentImage(calculateStatusImage(document.status))}
                            />
                          )
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        xs={1}
                        item
                        style={alignCenter}
                      >
                        {!document?.new?.read_at && (
                          <div className="category-unread-dot" />
                        )}
                        <img
                          alt="extension"
                          src={getDocumentImage(grabFileExtension(document.filename))}
                          className="document-info-extension-image"
                        />
                      </Grid>

                      <Grid item xs={3} style={alignCenter}>
                        <p
                          className="font-size-14 font-color-blue-grey vietnam-bold margin-left-10"
                        >
                          {document?.filename || ""}
                        </p>
                          {document?.category.parent_id == 4 && <p className="font-size-12 font-color-grey vietnam-bold margin-left-10 margin-top-4">{document?.category?.title || ""}</p>}
                          {document?.category_id == 4 && <p className="font-size-12 font-color-grey vietnam-bold margin-left-10 margin-top-4">Andere</p>}
                      </Grid>

                      {showConcerned && (
                        <Grid item xs={2} style={alignCenterItem}>
                          <p
                            className="font-size-12 font-color-grey vietnam-regular"
                          >
                            {document.concerned}
                          </p>
                        </Grid>
                      )}

                      <Grid item xs={2} style={alignCenterItem}>
                        <p
                          className="font-size-12 font-color-grey vietnam-regular"
                        >
                          {moment(document.created_at)
                            .format('DD/MM/YY')}
                        </p>
                      </Grid>

                      <Grid item xs={2} style={alignCenterItem}>
                        <p
                          className="font-size-12 font-color-grey vietnam-regular"
                        >
                          {document?.notifications?.created_at ? moment(document.notifications.created_at)
                            .format('DD/MM/YY') : '-'}
                        </p>
                      </Grid>

                      <Grid item xs={2} style={alignCenterItem}>
                        {showStatus && (
                          document.statusText ? (
                            <TooltipHandler
                              overlay={document.statusText}
                              trigger={['hover']}
                              placement="right"
                            >
                              <img
                                height={24}
                                alt="status"
                                src={getDocumentImage(calculateStatusImage(document.status))}
                              />
                            </TooltipHandler>
                          ) : (
                            <img
                              height={24}
                              alt="status"
                              src={getDocumentImage(calculateStatusImage(document.status))}
                            />
                          )
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              ))}
            </>
          ) : (
            <div className="text-center padding-top-20">
              Geen documenten aanwezig.
            </div>
          )}
        </>
      )}
      <DocSlideCard
        isOpen={toggle}
        document={documentData}
        setIsFetching={setIsFetching}
        showStatus={showStatus && !showConcerned}
        ShowSligingCard={ShowSligingCard}
        hasAccess = {hasAccess}
        buyerId={buyerId}
        triggerManualUpdate = {triggerManualUpdate}
      />
    </Grid>

  );
};

DocumentInfo.styles = {
  alignCenterItem: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  alignCenter: {
    alignSelf: 'center',
  },
};

DocumentInfo.defaultProps = {
  showConcerned: false,
  showStatus: false,
  isFetching: false,
};

DocumentInfo.propTypes = {
  documents: PropTypes.array.isRequired,
  showConcerned: PropTypes.bool,
  showStatus: PropTypes.bool,
  isFetching: PropTypes.bool,
};

export default DocumentInfo;

/* const OurFallbackComponent = ({
  error,
  componentStack,
  resetErrorBoundary,
}) => {
  return (
    <div>
      <h1>
        An error occurred:
        {error.message}
      </h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}; */
