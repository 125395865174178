import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SideCardHeader from '../SideCardHeader';
import MessagePanel from './message_panel/message_panel';

import './messages.scss';
import { getProjectMessagesList } from './messages.api';
import { getChatById, getChatsbyProjectUnitUser } from "../../../../../../common/api/routes";
import Spinner from '../../../../../../components/spinner/Spinner';
import SlidingCard from '../../../../../../components/shared/sliding_card/sliding_card';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  messageBox: {
    background: '#F0F1F2',
    padding: 15,
    color: '#45545D',
    margin: '15px 0',
    borderRadius: 5,
    fontSize: 14,

    '&:hover': {
      background: '#2EA0B4',
      color: '#F0F1F2',
      cursor: 'pointer',
    },
  },
  messagesDot: {
    textAlign: 'center',
    borderRadius: 50,
    background: '#D40000',
    padding: '1px 4px 4px',
    width: 15,
    color: 'white',
    fontSize: 12,
  },
}));

const Messages = forwardRef((props, ref) => {
  const [activeConversation, setActiveConversation] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [ buyerData, setBuyerData ] =  useState({});
  const [ hasAccess, setHasAccess ] = useState(false)

  const buyerId = localStorage.getItem('selected-project');
  const userData = JSON.parse(localStorage.getItem('user'))
  const classes = useStyles();
  const { isOpen, toggleVisibility, projectData, setMessagesCount } = props;

  useEffect(() => {
    let child_buyer = 0;
    if(userData.id === projectData.user_id){
      child_buyer = projectData.id
    }else{
      const currentUser = projectData?.childs?.find(user => user.user.id == userData.id);
      child_buyer = currentUser?.id;
    }
    if(projectData?.project?.id) {
      let standardMessages = [];
      let totalCount = 0;
      if(userData.id == projectData.user_id) {
        axios.get(getChatsbyProjectUnitUser(projectData.id, child_buyer)).then(response =>  {
          standardMessages = response.data.data;
          standardMessages.forEach(message => {
            totalCount += message.unread;
          })
        }).then(() => {
          axios.get(getChatById(projectData.id, child_buyer)).then(responseTwo => {
            standardMessages.unshift(responseTwo.data.data)
            totalCount += responseTwo.data.data.unread;
            setMessagesCount(totalCount)
            setMessages(standardMessages)
          }).catch(error => console.log(error))
        }).catch(error => console.log(error))
      }
      else {
        if(projectData.childs) {
          let standardMessages = [];
          let totalCount = 0;
          axios.get(getChatsbyProjectUnitUser(projectData.id, child_buyer)).then(response =>  {
            standardMessages = response.data.data;
            standardMessages.forEach(message => {
              totalCount += message.unread;
            })
            setMessagesCount(totalCount)
            setMessages(standardMessages)
          }).then(() => {
            axios.get(getChatById(projectData.id, child_buyer)).then(responseTwo => {
              if (responseTwo.data?.data) {
                standardMessages.unshift(responseTwo.data.data)
                totalCount += responseTwo.data.data.unread;
                setMessagesCount(totalCount)
                setMessages(standardMessages)
              }
            }).catch(error => console.log(error))
          }).catch(error => console.log(error))
        }
      }
    }
  }, [projectData]);

  useEffect(() => {
    if(projectData?.user) {
      // now we find the user in the buyer project object
      if(userData.id == projectData?.user?.id) {
        // this means that this is the main user and we select root access value
        if(projectData?.access == 0) {
          setHasAccess(true)
        }
      }
      else {
        const currentUser = projectData?.childs?.find(user => user.user.id == userData.id)
        if(currentUser?.access == 0) {
          setHasAccess(true)
        }
      }
    }
  }, [projectData])

  useImperativeHandle(ref, () => ({
    externSetActiveConversation: chatId => {
      // next we find the respective index of that data
      const chatIndex = messages.findIndex(message => message.id == chatId);
      setActiveConversation(chatIndex + 1)
    }
  }))

  return (
    <SlidingCard
      isOpen={isOpen}
      ShowSligingCard={() => {
        setTimeout(() => setActiveConversation(0), 300);
        return toggleVisibility();
      }}
    >
      <img
        src={require('../../../../../../assets/images/icon/cross_gray.svg')}
        alt="close"
        onClick={() => {
          setTimeout(() => setActiveConversation(0), 300);
          return toggleVisibility();
        }}
        className="cursor-pointer close-btn"
      />
      <img
        src={require('../../../../../../assets/images/iconround/back_gray.svg')}
        alt="back"
        onClick={() => {
          setTimeout(() => setActiveConversation(0), 300);
          return toggleVisibility();
        }}
        className="cursor-pointer back-btn"
      />
      <Grid container style={{ padding: 20 }}>
        <SideCardHeader
          title="Berichten"
          toggleVisibility={() => { setActiveConversation(0); toggleVisibility(); }}
          headerImage={require('../../../../../../assets/images/icon/chat_darkbleu.svg')}
        />

        {activeConversation === 0 ? (
          <Grid item xs={12}>
            {isFetching ? (
              <div className="text-center">
                <Spinner text="Fetching Messages.." />
              </div>
            ) : (
              <>
                {messages && messages.length ? (
                  messages && messages.map((message, messageIndex) => (
                    <Grid container xs={12} className={`${classes.messageBox} cursor-pointer`} alignItems = "center" onClick={() => setActiveConversation(messageIndex + 1)}>
                      <Grid item xs={1}>
                        <div className={message?.unread > 0 ? classes.messagesDot : ''}>
                          {message?.unread || ""}
                        </div>
                      </Grid>
                      <Grid item xs={11}>
                        {(message?.buyer_project_id && !message?.document_id) ? 'Algemeen' : `Document ${message?.document?.filename}`}
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <div className="padding-top-20" style={{ textAlign: 'center' }}>
                    No new Messages.
                  </div>
                )}
              </>
            )}
          </Grid>
        ) : (
          <Grid container>
            <MessagePanel data = {messages[activeConversation - 1]} hasAccess = {hasAccess} />
          </Grid>
        )}
      </Grid>
    </SlidingCard>
  );
})

export default Messages;

Messages.propTypes = {
  toggleVisibility: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

Messages.defaultProps = {
  isOpen: false,
};
