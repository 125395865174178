import React, { useState, useEffect, useRef } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { delay } from "lodash";
import axios from "axios";

import './header.scss';
import MessagesSideCard from '../content/sidecard/messages/messages';
import NotificationSideCard from '../content/sidecard/notification/notification';
import Project from "../content/sidecard/project/project";

import { getAllNotificationByUser, getAllBuyerProjects } from "../../../../common/api/routes";
import { getProjectMessagesList } from '../content/sidecard/messages/messages.api';
import { SQLDateToString, getDutchMonthName } from "../../../../common/utils/format";
 
export default function Header(props) {
  const [isHamburgerClick, setHamburgerState] = useState(false);
  const [isMessagesOpen, setMessagesOpen] = useState(false);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [isProjectOpen, setProjectOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [onMouseEnter, setMouseEnterState] = useState(0);
  const [ isUpdated, setIsUpdated ] = useState(false);
  const [ projectData, setProjectData ] = useState({});
  const [ notificationList, setNotificationList ] = useState([]);
  const [ messagesCount, setMessagesCount ] = useState(0)

  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('user'));
  const messageSidecardRef = useRef(0);

  useEffect(() => {
    // this makes the notification and messages realtime
    // we keep the update frequency 5 seconds
    delay(() => {
      setIsUpdated(!isUpdated);
    }, 5000);
  }, [isUpdated])

  useEffect(() => {
    if(props.projectData)  {
      setProjectData(props.projectData)
    }
  }, [props.projectData])

  useEffect(() => {
    if(props.loadedMessage?.id) {
      setMessagesOpen(true);
    }
  }, [props.loadedMessage])

  useEffect(() => {
    if(projectData?.project?.id) {
      if(userData.id == projectData.user_id) {
        axios.get(getAllNotificationByUser(userData.id, projectData?.project?.id, projectData.id)).then(response => {
          setNotificationList(response.data.data)
        }).catch(error => console.log(error))
      }
      else {
        if(projectData?.childs) {
          let secondaryUser = projectData.childs.find(user => user.user_id == userData.id)
          if(secondaryUser) {
            axios.get(getAllNotificationByUser(userData.id, projectData?.project?.id, secondaryUser.id)).then(response => {
              setNotificationList(response.data.data)
            }).catch(error => console.log(error))
          }
        }
      }
    }
  }, [projectData, isUpdated])

  const logout = () => {
    localStorage.clear();
    history.push('/')
  }

  const handleMessageNotifClick = chatId =>  {
    setMessagesOpen(true);
    setNotificationOpen(false);
    messageSidecardRef.current.externSetActiveConversation(chatId);
  }

  const handleDocumentNotifClick = documentId => {
    history.push(`/dashboard/projects?type=1&id=${documentId}`);
  }

  return (
    <Grid container className="fade-in-translate z-index-99999" tabIndex = "1" onBlur = {() => setHamburgerState(false)}>

      <MessagesSideCard toggleVisibility={setMessagesOpen} isOpen={isMessagesOpen} projectData = {projectData} setMessagesCount = {setMessagesCount} ref = {messageSidecardRef} />
      <NotificationSideCard toggleVisibility={setNotificationOpen} isOpen={isNotificationOpen} notificationList = {notificationList} setNotificationList = {setNotificationList} handleMessageNotifClick = {handleMessageNotifClick} handleDocumentNotifClick = {handleDocumentNotifClick} />
      <Project toggleVisibility={setProjectOpen} isOpen={isProjectOpen} />

      <Hidden smDown>
        <Grid
          container
          className="header-container background-color-dark-grey"
        >
          <div
            className="animatable-100 fade-in-translate header-white-part-container width-auto flex-row background-color-white vertical-center z-index-9999"
          >
            <div
              className="width-60-p height-80-p flex horizontal-between vertical-center margin-left-100"
            >
              <button
                type="button"
                className={`cursor-pointer flex-row height-auto center-content padding-left-10 ${(selectedIndex === 1 || onMouseEnter === 1) && 'header-selected-item '}`}
                onClick={() => {
                  setSelectedIndex(1);
                  setProjectOpen(true);
                }}
                onMouseEnter={() => {
                  setMouseEnterState(1);
                }}
                onMouseLeave={() => {
                  if (selectedIndex !== 1) setMouseEnterState(0);
                }}
              >
                <img
                  alt="building"
                  src={require('../../../../assets/images/icon/building_darkbleu.svg')}
                  className="header-icons margin-right-10"
                />
                <p
                  className="font-color-blue-grey vietnam-semi-bold font-size-14 margin-right-10"
                >
                  Project
                </p>
              </button>
              <div
                className={`cursor-pointer flex-row height-auto center-content padding-left-10 ${(selectedIndex === 2 || onMouseEnter === 2) && 'header-selected-item '}`}
                onClick={() => {
                  setSelectedIndex(2);
                  setNotificationOpen(true);
                  setMessagesOpen(false);
                }}
                onMouseEnter={() => {
                  setMouseEnterState(2);
                }}
                onMouseLeave={() => {
                  if (selectedIndex !== 2) setMouseEnterState(0);
                }}
              >
                <img
                  alt="notificaties"
                  src={require('../../../../assets/images/icon/notif_darkbleu.svg')}
                  className="header-icons margin-right-10"
                />
                {notificationList?.length > 0 && (
                 <div
                    className="absolute header-notif-bubble margin-right-80 center-content"
                  >
                    <p
                      className="font-color-white font-size-7 vietnam-semi-bold"
                    >
                      {notificationList?.length || 0}
                    </p>
                  </div>
                )}
                <p
                  className="font-color-blue-grey vietnam-semi-bold font-size-14 margin-right-10"
                >
                  Notificaties
                </p>
              </div>
              <div
                className={`cursor-pointer flex-row height-auto center-content padding-left-10 ${(selectedIndex === 3 || onMouseEnter === 3) && 'header-selected-item '}`}
                onClick={() => {
                  setSelectedIndex(3);
                  setMessagesOpen(true);
                  setNotificationOpen(false);
                }}
                onMouseEnter={() => {
                  setMouseEnterState(3);
                }}
                onMouseLeave={() => {
                  if (selectedIndex !== 3) setMouseEnterState(0);
                }}
              >
                <img
                  alt="chat"
                  src={require('../../../../assets/images/icon/chat_darkbleu.svg')}
                  className="header-icons margin-right-10"
                />
                {messagesCount > 0 && (
                  <div
                  className="absolute header-notif-bubble margin-right-65 center-content "
                >
                  <p
                    className="font-color-white font-size-7 vietnam-semi-bold"
                  >
                    {messagesCount}
                  </p>
                </div>
                )}
                <p
                  className="font-color-blue-grey vietnam-semi-bold font-size-14 margin-right-10"
                >
                  Berichten
                </p>
              </div>
              <div
                className="height-auto relative flex-column vertical-center"
                onClick={() => {
                  setSelectedIndex(4);
                }}
                onMouseEnter={() => {
                  setMouseEnterState(4);
                }}
                onMouseLeave={() => {
                  setMouseEnterState(0);
                }}
              >
                <div
                  className={`cursor-pointer flex-row height-auto center-content padding-left-10 ${(selectedIndex === 4 || onMouseEnter === 4) && 'header-selected-item '}`}
                >
                  <img
                    alt="person"
                    src={require('../../../../assets/images/icon/person_darkbleu.svg')}
                    className="header-icons margin-right-10"
                  />
                  <p
                    className="font-color-blue-grey vietnam-semi-bold font-size-14 margin-right-10"
                  >
                    Account
                  </p>
                </div>
                <div className="header-dropdown-container animatable-400 ease-in-out-quart" style={{ maxHeight: onMouseEnter === 4 ? '300px' : '0px', opacity: onMouseEnter === 4 ? 1 : 0 }}>
                  <div className="flex-row width-auto horizontal-end">
                    <div className="header-dropdown-arrow" />
                  </div>
                  <div className="header-dropdown">
                    <button type="button" className="header-dropdown-row animatable-400 ease-in-out-quart" onClick={() => history.push('/dashboard/profile')}>
                      <p className="main-font-regular font-size-14 font-color-black margin-right-30 animatable-400 ease-in-out-quart">Mijn profiel</p>
                    </button>
                    <button type="button" className="header-dropdown-row animatable-400 ease-in-out-quart" onClick={() => history.push('/dashboard/privacy')}>
                      <p className="main-font-regular font-size-14 font-color-black margin-right-30 animatable-400 ease-in-out-quart">Privacy instellingen</p>
                    </button>
                    <button type="button" className="header-dropdown-row animatable-400 ease-in-out-quart" onClick={() => history.push('/dashboard/terms')}>
                      <p className="main-font-regular font-size-14 font-color-black margin-right-30 animatable-400 ease-in-out-quart">Gebruiksvoorwaarden</p>
                    </button>
                    <button type="button" className="header-dropdown-row animatable-400 ease-in-out-quart" onClick={logout}>
                      <p className="main-font-regular font-size-14 font-color-black margin-right-30 animatable-400 ease-in-out-quart">Afmelden</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="width-auto flex-row vertical-center horizontal-end "
            >
              <img
                alt="logo"
                src={require('../../../../assets/images/logo/logo.png')}
                className="header-logo margin-right-120 cursor-pointer" 
                onClick = {() => history.push("/dashboard/projects")}
              />
            </div>
          </div>
          <div className="fade-in-translate width-auto flex-row margin-left-110">
            <div className="flex-column margin-right-131">
              <p
                className="font-color-white vietnam-bold font-size-16"
              >
                {projectData?.project?.name || "Project Naam"}
              </p>
              <div className="header-green-line margin-top-5" />
              <p
                className="font-color-white vietnam-regular font-size-14 margin-top-5"
              >
                {projectData?.created_at ?  getDutchMonthName(projectData?.created_at) : "16 Maart 2021"}
              </p>
            </div>
            <div className="flex-column margin-right-131">
              {projectData && projectData?.user_units && projectData.user_units.length > 0 && projectData.user_units.map(unit => (
                <p
                  className="font-color-white vietnam-regular font-size-14 "
                >
                  {unit?.units?.type?.title || ""} {unit?.units?.general_number || ""}
                </p>
              ))}
            </div>
            <div className="flex-column margin-right-131">
              <p
                className="font-color-white vietnam-regular font-size-14 "
              >
                {projectData?.user?.firstName || ""} {projectData?.user?.lastName || ""}
              </p>
              {projectData?.childs && projectData?.childs?.length > 0 && projectData?.childs.map((user, userIndex) => (
                <>
                  {userIndex < 3 && (
                    <p
                      className="font-color-white vietnam-regular font-size-14 "
                    >
                      {user?.user?.firstName || ""} {user?.user?.lastName || ""}
                    </p>
                  )}
                </>
              ))}
            </div>
            <div className="flex-column margin-right-131">
              {projectData?.childs && projectData?.childs?.length > 0 && projectData?.childs.map((user, userIndex) => (
                <>
                  {userIndex > 2 && (
                    <p
                      className="font-color-white vietnam-regular font-size-14 "
                    >
                      {user?.user?.firstName || ""} {user?.user?.lastName || ""}
                    </p>
                  )}
                </>
              ))}
            </div>
          </div>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          className="animatable-400 fade-in-out-quart height-auto background-color-dark-grey"
          style={{
            height: isHamburgerClick ? '550px' : '0px',
            opacity: isHamburgerClick ? 1 : 0,
          }}
        >
          <div
            className="flex-row width-auto vertical-center horizontal-between padding-left-10 padding-right-10 padding-top-20 padding-bottom-20 "
          >
            <div className="flex-column">
              <p
                className="font-color-white vietnam-bold font-size-16"
              >
                {projectData?.project?.name || "Project Naam"}
              </p>
              <div className="header-green-line margin-top-5" />
              <p
                className="font-color-white vietnam-regular font-size-14 margin-top-5"
              >
                {projectData?.project?.created_at ?  getDutchMonthName(projectData?.project?.created_at) : "16 Maart 2021"}
              </p>
            </div>
            <img
              alt="list"
              src={require('../../../../assets/images/icon/menu_quit.svg')}
              className="header-menu cursor-pointer"
              onClick={() => {
                setHamburgerState(!isHamburgerClick);
                setSelectedIndex(0);
              }}
            />
          </div>
          <div
            className={`cursor-pointer header-dropdown-item center-content ${selectedIndex === 1 && 'background-color-light-blue-grey'}`}
            onClick={() => {
              setSelectedIndex(1);
              setHamburgerState(false)
              history.push("/dashboard/projects")
            }}
            style={!isHamburgerClick ? { display: 'none' } : {}}
          >
            <p
              className="font-color-white vietnam-semi-bold font-size-16"
            >
              Documenten
            </p>
          </div>
          <div
            className={`cursor-pointer header-dropdown-item center-content ${selectedIndex === 2 && 'background-color-light-blue-grey'}`}
            onClick={() => {
              setSelectedIndex(2);
              setHamburgerState(false)
              setProjectOpen(true);
            }}
            style={!isHamburgerClick ? { display: 'none' } : {}}
          >
            <p
              className="font-color-white vietnam-semi-bold font-size-16"
            >
              Project
            </p>
          </div>
          <div
            className={`cursor-pointer header-dropdown-item center-content ${selectedIndex === 3 && 'background-color-light-blue-grey'}`}
            onClick={() => {
              setSelectedIndex(3);
              setNotificationOpen(true);
              setHamburgerState(false)
              setMessagesOpen(false);
            }}
            style={!isHamburgerClick ? { display: 'none' } : {}}
          >
            <div className='relative'>
              {notificationList?.length > 0 && (
                <div
                  className="absolute header-notif-bubble margin-right-80 center-content"
                >
                  <p
                    className="font-color-white font-size-7 vietnam-semi-bold"
                  >
                    {notificationList?.length || 0}
                  </p>
                </div>
              )}
              <p
                className="font-color-white vietnam-semi-bold font-size-16"
              >
                Notificaties
              </p>
            </div>
          </div>
          <div
            className={`cursor-pointer header-dropdown-item center-content ${selectedIndex === 4 && 'background-color-light-blue-grey'}`}
            onClick={() => {
              setSelectedIndex(4);
              setMessagesOpen(true);
              setHamburgerState(false)
              setNotificationOpen(false);
            }}
            style={!isHamburgerClick ? { display: 'none' } : {}}
          >
            <div className= "relative">
              {messagesCount > 0 && (
                <div
                  className="absolute header-notif-bubble margin-right-65 center-content "
                >
                  <p
                    className="font-color-white font-size-7 vietnam-semi-bold"
                  >
                    {messagesCount}
                  </p>
                </div>
              )}
              <p
                className="font-color-white vietnam-semi-bold font-size-16"
              >
                Berichten
              </p>
            </div>
          </div>
          <div className = "flex-column width-auto">
            <div
              className={`cursor-pointer header-dropdown-item flex-column center-content ${selectedIndex === 5 && 'background-color-light-blue-grey'}`}
              onClick={() => {
                setSelectedIndex(selectedIndex !== 5 ? 5 : 0);
              }}
              style={!isHamburgerClick ? { display: 'none' } : {}}
            >
              <p
                className="font-color-white vietnam-semi-bold font-size-16"
              >
                Account
              </p>
            </div>
            <div className = "width-auto flex-column overflow-hidden" style = {{height: selectedIndex === 5 ? 'auto' : '0px'}} >
              <div className = "cursor-pointer header-dropdown-item center-content" onClick = {() => {
                setHamburgerState(false)
                history.push('/dashboard/profile')
              }}>
                <p
                  className="font-color-white vietnam-semi-regular font-size-14"
                >
                  Mijn profiel
                </p>
              </div>
              <div className = "cursor-pointer header-dropdown-item center-content" onClick = {() => {
                setHamburgerState(false)
                history.push('/dashboard/terms')
              }}>
                <p
                  className="font-color-white vietnam-semi-regular font-size-14"
                >
                  Privacy instellingen
                </p>
              </div>
              <div className = "cursor-pointer header-dropdown-item center-content"onClick = {() => {
                setHamburgerState(false)
                history.push('/dashboard/privacy')
              }}>
                <p
                  className="font-color-white vietnam-semi-regular font-size-14"
                >
                  Gebruiksvoorwaarden
                </p>
              </div>
              <div className = "cursor-pointer header-dropdown-item center-content"onClick = {() => {
                setHamburgerState(false)
                history.push('/')
              }}>
                <p
                  className="font-color-white vietnam-semi-regular font-size-14"
                >
                  Afmelden
                </p>
              </div>
            </div>
          </div>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
        <Grid
          container
          className="animatable-400 fade-in-out-quart background-color-dark-grey"
          style={{
            height: isHamburgerClick ? '0px' : 'auto',
            opacity: isHamburgerClick ? 0 : 1,
            padding: isHamburgerClick ? 0 : '10px 20px',
          }}
        >
          <div
            className="flex-row width-auto vertical-center horizontal-between"
          >
            <div className="flex-column">
              <p
                className="font-color-white vietnam-bold font-size-16"
              >
                {projectData?.project?.name || "Project Naam"}
              </p>
              <div className="header-green-line margin-top-5" />
              <p
                className="font-color-white vietnam-regular font-size-14 margin-top-5"
              >
                {projectData?.project?.created_at ?  getDutchMonthName(projectData?.project?.created_at) : "16 Maart 2021"}
              </p>
            </div>
            <img
              alt="list"
              src={require('../../../../assets/images/icon/menu.svg')}
              className="header-menu cursor-pointer"
              onClick={() => {
                setHamburgerState(!isHamburgerClick);
                setSelectedIndex(0);
              }}
            />
          </div>
          <div
            className="background-color-dark-grey padding-bottom-15"
          >
            <div className = "flex-row">
              {projectData && projectData?.user_units && projectData.user_units.length > 0 && projectData.user_units.map(unit => (
                <p
                  className="font-color-white vietnam-regular font-size-14 margin-top-10 text-ellipsis "
                >
                  {unit?.units?.type?.title || ""} {unit?.units?.general_number || ""} &nbsp;
                </p>
              ))}
            </div>
            <div className = "flex-row">
                <p
                  className="font-color-white vietnam-regular font-size-14 margin-top-10 text-ellipsis "
                >
                  {projectData?.user?.firstName || ""} {projectData?.user?.lastName || ""} &nbsp;
                </p>
              {projectData?.childs && projectData?.childs?.length > 0 && projectData?.childs.map(user => (
                <p
                  className="font-color-white vietnam-regular font-size-14 margin-top-10 text-ellipsis "
                >
                  {user?.user?.firstName || ""} {user?.user?.lastName || ""} &nbsp;
                </p>
              ))}
            </div>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
}
