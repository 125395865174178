/* eslint react/no-danger: 0 */
/* eslint react/jsx-props-no-spreading: 0 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

class TooltipHandler extends PureComponent {
  render() {
    const { html, overlay, ...props } = this.props;
    return (
      <Tooltip
        overlay={html ? <span dangerouslySetInnerHTML={{ __html: overlay }} /> : overlay}
        {...props}
      >
        {this.props.children}
      </Tooltip>
    );
  }
}

TooltipHandler.defaultProps = {
  overlay: '',
  html: false,
};

TooltipHandler.propTypes = {
  html: PropTypes.bool,
  overlay: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TooltipHandler;
