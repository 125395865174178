import request from '../request';

export const getDocument = (documentId, setFetching, setResponse, setError) => {
  setFetching(true);
  const response = request.get(`/document/${documentId}`);
  response
    .then((res) => {
      setFetching(false);
      setResponse(res.data.data);
    })
    .catch((err) => {
      setFetching(false);
      setError(err.response);
    });
};

export const updateDocument = (documentId, payload, setFetching) => {
  setFetching(true);

  const response = request.put(`/document/${documentId}`, payload, {
    'Content-Type': 'application/json',
  }, false);
  const dataPromise = response.then((res) => res.data.data);
  setFetching(false);
  return dataPromise;
};
