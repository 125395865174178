import React from 'react';
import { Grid, Hidden } from '@material-ui/core';

import { SpaceRow } from '../../../../../components/space/Space';
import Footer from "../../footer/Footer";

import './terms.scss';

export default function Terms() {
  return (
    <div className="terms-container">
      <Grid container justify="center">
        <Grid container item xs={11}>
          <SpaceRow top="24" />
          <Grid container>
            <p className="main-font-bold font-size-20 font-color-black">Gebruiksvoorwaarden</p>
          </Grid>
          <SpaceRow top="24" />
          <Grid container>
            <p className="main-font-regular font-size-14 font-color-blue-grey">Het bezoeken, doorbladeren en bekijken van deze website valt onder onze gebruiksvoorwaarden en onder alle toepasselijke wetten. Door deze website te bezoeken, te doorbladeren en te bekijken, aanvaardt u de gebruiksvoorwaarden, zoals hieronder beschreven, zonder beperking of uitsluiting.</p>
          </Grid>
          <SpaceRow top="25" />
          <Grid container>
            <p className="main-font-bold font-size-20 font-color-black">Auteursrecht</p>
          </Grid>
          <SpaceRow top="10" />
          <Grid container>
            <p className="main-font-regular font-size-14 font-color-blue-grey">De inhoud van deze website is auteursrechtelijk beschermd. De auteursrechten berusten bij Gaelens Bouwprojecten, haar klanten en haar partners. Alle rechten zijn voorbehouden. Informatie van de website (waaronder maar niet uitsluitend tekst, presentaties, illustraties, foto's en geluid) mag, tenzij anders is aangegeven, niet worden gekopieerd, overgeplaatst, verspreid of opgeslagen zonder schriftelijke toestemming vooraf van Gaelens Bouwprojecten. Wijzigingen op de inhoud van de site zijn verboden. Delen van de site bevatten illustraties die uitdrukkelijk onder het auteursrecht van de klanten vallen.</p>
          </Grid>
          <SpaceRow top="25" />
          <Grid container>
            <p className="main-font-bold font-size-20 font-color-black">Opmerkingen, vragen en suggesties</p>
          </Grid>
          <SpaceRow top="10" />
          <Grid container>
            <p className="main-font-regular font-size-14 font-color-blue-grey">Gaelens Bouwprojecten wenst geen vertrouwelijke of voorbehouden informatie te ontvangen via deze website. Alle door u aan ons verstrekte informatie (suggesties, ideeën en overige) zal worden beschouwd als niet-vertrouwelijk en openbaar (uitgezonderd zoals beschreven in punt 1. met betrekking tot de bescherming van de persoonlijke levenssfeer). Door Gaelens Bouwprojecten materiaal te sturen, geeft u aan Gaelens Bouwprojecten het onbeperkte en onherroepbare recht deze informatie te gebruiken, te vertonen, te wijzigen, te verwerken en te verspreiden en deze informatie rechtelijk te registreren. Tevens kan Gaelens Bouwprojecten de ideeën en technieken vrij toepassen die u ons laat toekomen. Gaelens Bouwprojecten heeft deze website met de grootst mogelijke zorg samengesteld. Gaelens Bouwprojecten houdt zich aanbevolen voor suggesties of commentaar. Indien bezwaar bestaat tegen bepaalde teksten of afbeeldingen, kan dat ook kenbaar worden gemaakt.</p>
          </Grid>
          <SpaceRow top="25" />
          <Grid container>
            <p className="main-font-bold font-size-20 font-color-black">Aansprakelijkheid</p>
          </Grid>
          <SpaceRow top="10" />
          <Grid container>
            <p className="main-font-regular font-size-14 font-color-blue-grey">Aan de informatie, die op deze website getoond wordt, kunnen op geen enkele wijze rechten of aanspraken ontleend worden. Links naar andere websites worden als service aangeboden en het betekent niet dat Gaelens Bouwprojecten verantwoordelijk is voor de inhoud van deze websites. Bovendien is het uw eigen verantwoordelijkheid te controleren of deze websites vrij zijn van virussen en andere zaken met een schadelijk karakter. Informatie op onze website kan door ons worden gewijzigd zonder voorafgaande aankondiging of verplichting.</p>
          </Grid>
          <SpaceRow top="25" />
          <Grid container>
            <p className="main-font-bold font-size-20 font-color-black">Rechtspraak</p>
          </Grid>
          <SpaceRow top="10" />
          <Grid container>
            <p className="main-font-regular font-size-14 font-color-blue-grey">Het staat Gaelens Bouwprojecten vrij op elk moment de gebruiksvoorwaarden te wijzigen door die opnieuw op deze website te publiceren. De gebruiksvoorwaarden zijn conform de Belgische rechtspraak en de geschillen zullen beslecht worden conform diezelfde rechtspraak. In het geval dat een onderdeel van deze gebruiksvoorwaarden door een rechtbank met toepasselijke jurisdictie ongeldig of onuitvoerbaar zal worden verklaard, zal het betreffende deel van de gebruiksvoorwaarden als vervallen beschouwd worden, terwijl de resterende bepalingen van deze gebruiksvoorwaarden in volle geldigheid en werking van kracht zullen blijven.</p>
          </Grid>
          <SpaceRow top="25" />
        </Grid>
      </Grid>
      <Hidden smUp>
        <Footer />
      </Hidden>
    </div>
  );
}
