import React, { useEffect, useState, useRef } from 'react';
import { Grid, Container } from '@material-ui/core';
import axios from "axios";
import { useHistory } from 'react-router-dom';

import { SpaceRow } from '../../../components/space/Space';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import ErrorModal from "../../../components/error_modal/error_modal"
import { forgotPassword } from "../../../common/api/routes";
import ErrorMessage from "../../../components/error-message/ErrorMessage";

import "./forgot_password.scss";
import "../login.scss";

export default function ForgotPassword() {
    const [emailState, setEmailState] = useState('');
    const [ loadState, setLoadState ] = useState(false);
    const [ lastErrorState, setLastErrorState ] = useState(null);

    const [emailStateError, setEmailStateError] = useState(false);

    const history =  useHistory();
    const errorModalRef = useRef(0)
    
    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [])

    const handleEmailChange = (event) => {
        if (event.target.validity.valid) {
        setEmailStateError(false);
        } else {
        setEmailStateError(true);
        }
        setEmailState(event.target.value);
    };

    const isValid = () => {
        let validity = true;
        if (emailState === '' || emailStateError) {
            validity = false;
            setEmailStateError(true)
        }
        return validity;
    }; 

    const handleLoginEvent = () => {
        if(isValid() && !loadState) {
            axios.post(forgotPassword, { 
                email: emailState,
                type: 0,
            }).then(response => {
                if(response.data.success) {
                    errorModalRef.current.openModal('Email verzonden, kijk in je inbox om je paswoord te resetten.', 'Succes')
                    setEmailState("")
                }
                else {
                    errorModalRef.current.openModal('Email verzonden, kijk in je inbox om je paswoord te resetten.', 'Succes')
                    setEmailState("")
                }
            }).catch(error => {
                setLastErrorState(error.response?.data?.data)
                setLoadState(false)
            })
        }
    }

    const handleKeyPress = (event) => {
        if(event.keyCode === 13) {
            handleLoginEvent();
        }
    }

    return(
        <Grid container>
      <div className="container-full">
        <div className="login-background center-content">

          <Container maxWidth="lg">

            <Grid container justify="center">
              <div className="login-box-container center-content relative">
                <div className="login-box-layer" />
                <div className="login-box vertical-center relative">

                  <Grid container justify="center">
                    <img
                      src={require('../../../assets/images/logo/logo_white.png')}
                      className="login-logo"
                      alt="logo"
                    />
                  </Grid>
                    <Grid container >
                        <SpaceRow top="45" />
                        <Grid container>
                        <div className="login-input-container">
                            <div
                            className="login-input-container-layer animatable-400 ease-in-out-quart"
                            style={{ transform: emailStateError ? 'scaleX(1)' : 'scaleX(0)' }}
                            />
                            <div className="login-input-container-main">
                            <img
                                className="login-input-icon flex-shrink-disable"
                                src={require('../../../assets/images/icon/person_white.svg')}
                                alt="person white"
                            />
                            <input
                                className="login-input flex-shrink-disable main-font-regular font-size-14 font-color-white"
                                value={emailState}
                                pattern="[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+"
                                placeholder="E-mailadres"
                                onChange={handleEmailChange}
                            />
                            </div>
                        </div>
                        </Grid>
                        <SpaceRow top="45" />

                        <Grid container justify = "space-between" className = "relative">
                            <Grid container item xs = {6}>
                                <Grid container item xs = {11}>
                                    <button
                                        type="button"
                                        className="login-button center-content animatable-400 ease-in-out-quart"
                                        onClick = {() => history.push("/")}
                                    >
                                        <p
                                        className="main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart"
                                        >
                                            Terug
                                        </p>
                                    </button>
                                </Grid>
                            </Grid>
                            <Grid container justify = "flex-end" item xs = {6}>
                                <Grid container item xs = {11}>
                                    <button
                                        type="button"
                                        className="login-button center-content animatable-400 ease-in-out-quart"
                                        onClick = {handleLoginEvent}
                                    >
                                        <p
                                        className="main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart"
                                        >
                                            Versturen
                                        </p>
                                    </button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ErrorModal ref = {errorModalRef} />
                    </Grid>
                </div>
              </div>
            </Grid>

          </Container>

        </div>
      </div>
    </Grid>
    )
}