/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './_sliding_card.scss';

const SlidingCard = ({
  isOpen,
  ShowSligingCard,
  children,
}) => {
  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      width: '455px',
      borderRadius: '25px 0px 0px 25px',
      boxShadow: '-4px 0px 4px 0px rgba(214, 214, 214, 0.5)',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '0px',
        width: '100%',
      },
    },
  }));
  const classes = useStyles();
  return (
    <React.Fragment key="right">
      <Drawer
        anchor="right"
        BackdropProps={{ invisible: true }}
        open={isOpen}
        onBackdropClick={() => ShowSligingCard()}
        style={{ zIndex: 999999 }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {children}
      </Drawer>
    </React.Fragment>
  );
};
SlidingCard.defaultProps = {
  isOpen: false,
  ShowSligingCard: () => { return false; },
  children: React.createElement('div'),
};

SlidingCard.propTypes = {
  ShowSligingCard: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.element,
};
export default SlidingCard;
