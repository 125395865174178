import React, { useState } from 'react';
import { Grid, Container } from '@material-ui/core';

import Login from "./login/login";
import NewPassword from "./new_password/new_password";
import ProjectSelect from "./project_select/project_select";

import './login.scss';

const LoginPage = () => {
  const [ loginPhaseState, setLoginPhaseState ] = useState(1)

  return (
    <Grid container>
      <div className="container-full">
        <div className="login-background center-content">

          <Container maxWidth="lg">

            <Grid container justify="center">
              <div className="login-box-container center-content relative">
                <div className="login-box-layer" />
                <div className="login-box vertical-center relative">

                  <Grid container justify="center">
                    <img
                      src={require('../../assets/images/logo/logo_white.png')}
                      className="login-logo"
                      alt="logo"
                    />
                  </Grid>
                  {loginPhaseState === 1 && (
                    <Login changePhase = {setLoginPhaseState} />
                  )}
                  {loginPhaseState === 2 && (
                    <NewPassword changePhase = {setLoginPhaseState} />
                  )}
                  {loginPhaseState === 3 && (
                    <ProjectSelect />
                  )}
                </div>
              </div>
            </Grid>

          </Container>

        </div>
      </div>
    </Grid>
  );
};

export default LoginPage;
